import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');



  const predefinedUsername = `${process.env.REACT_APP_USERNAME}`;
  const predefinedPassword = `${process.env.REACT_APP_PASSWORD}`;

  const handleLogin = () => {
    if (username === predefinedUsername && password === predefinedPassword) {
      onLogin();
      // Save authentication status to local storage
      localStorage.setItem('isAuthenticated', 'true');
    } else {
      setError('Invalid username or password');
      console.log('Username:', process.env.REACT_APP_USERNAME);
console.log('Password:', process.env.REACT_APP_PASSWORD);

    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        style={{ marginBottom: '10px', padding: '8px', width: '200px' }}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        style={{ marginBottom: '10px', padding: '8px', width: '200px' }}
      />
      <button onClick={handleLogin} style={{ padding: '8px 16px', cursor: 'pointer' }}>
        Login
      </button>
      {error && <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>}
    </div>
  );
};

const MainApp = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check if the user is already authenticated (saved in local storage)
    const authStatus = localStorage.getItem('isAuthenticated');
    if (authStatus === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated'); // Remove authentication status on logout
  };

  return (
    <>
      {isAuthenticated ? (
        <>
          <App />
          <button
            onClick={handleLogout}
            style={{
              position: 'fixed',
              top: '20px',
              right: '20px',
              padding: '10px 20px',
              backgroundColor: '#d32f2f',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Logout
          </button>
        </>
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </>
  );
};

ReactDOM.render(<MainApp />, document.getElementById('root'));
