import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, TextField, Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@mui/material';
import axios from 'axios';

const UserManagement = () => {
  const [captains, setCaptains] = useState([]);
  const [newUsername, setNewUsername] = useState('');

  useEffect(() => {
    fetchCaptains();
  }, []);

  const fetchCaptains = async () => {
    try {
      const response = await axios.get('https://backend-cheeron.au-ki.com/api/captains');
      setCaptains(response.data);
    } catch (error) {
      console.error("Error fetching captains:", error);
      alert("Error fetching captains: " + (error.response?.data?.message || error.message));
    }
  };

  const handleAddCaptain = async () => {
    if (!newUsername.trim()) return;
    try {
      await axios.post('https://backend-cheeron.au-ki.com/api/captains', { username: newUsername });
      // Refresh list after adding
      fetchCaptains();
      setNewUsername('');
    } catch (error) {
      console.error("Error adding captain:", error);
      // Show the actual error details from the backend
      if (error.response && error.response.data) {
        alert("Error: " + JSON.stringify(error.response.data));
      } else {
        alert("An error occurred while adding the captain: " + error.message);
      }
    }
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>Captain Management</Typography>
      <Box component="form" noValidate autoComplete="off" mb={2} display="flex" gap={2}>
        <TextField 
          label="Username" 
          variant="outlined" 
          value={newUsername} 
          onChange={(e) => setNewUsername(e.target.value)} 
        />
        <Button variant="contained" color="primary" onClick={handleAddCaptain}>
          Add Captain
        </Button>
      </Box>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {captains.map(captain => (
              <TableRow key={captain.captain_id}>
                <TableCell>{captain.captain_id}</TableCell>
                <TableCell>{captain.username}</TableCell>
                <TableCell>{new Date(captain.created_at).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
};

export default UserManagement;
