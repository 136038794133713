import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  TablePagination
} from "@mui/material";

const GridComponent = ({
  restaurants,
  restaurantId,
  setRestaurantId,
  restaurantName,
  setRestaurantName,
  categories,
  selectedCategory,
  setSelectedCategory,
  menuItems,
  setMenuItems,
  isLoading,
  handleFieldChange,
  handleUpdate,
  handleDelete,
  showAlert,
  fetchMenuItems,
  handleVariationChange,
  handleVariationUpdate,
}) => {
  const [expandedDescriptionId, setExpandedDescriptionId] = useState(null);
  const descriptionRef = useRef(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filteredItems, setFilteredItems] = useState(menuItems);
  const [currentPage, setCurrentPage] = useState(0); // Current page index
  const [rowsPerPage, setRowsPerPage] = useState(10); // Items per page

  const toggleExpandDescription = (id) => {
    setExpandedDescriptionId(id === expandedDescriptionId ? null : id);
  };

  const handleClickOutside = (event) => {
    if (
      descriptionRef.current &&
      !descriptionRef.current.contains(event.target)
    ) {
      setExpandedDescriptionId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    fetchMenuItems();
  }, [restaurantId]);

  useEffect(() => {
    // Instant filtering based on search keyword
    const newFilteredItems = searchKeyword
      ? menuItems.filter((item) =>
          item.name.toLowerCase().includes(searchKeyword.toLowerCase())
        )
      : menuItems;

    setFilteredItems(newFilteredItems);
    if (searchKeyword) {
      setCurrentPage(0); // Reset to the first page only for filtering
    }
  }, [searchKeyword, menuItems]);

  const paginatedItems = filteredItems.slice(
    currentPage * rowsPerPage,
    currentPage * rowsPerPage + rowsPerPage
  );

  // Handle change in page
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // Handle change in rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0); // Reset to first page when rows per page changes
  };

  const handleOrderTypeChange = (id, option) => {
    setMenuItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? {
              ...item,
              ordertype: item.ordertype.includes(option)
                ? item.ordertype.filter((type) => type !== option)
                : [...item.ordertype, option],
            }
          : item
      )
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
      {/* Restaurant Dropdown */}
      <FormControl style={{ width: "35%" }}>
        <InputLabel>Restaurant</InputLabel>
        <Select
          value={restaurantId}
          onChange={(e) => {
            setRestaurantId(e.target.value);
            setRestaurantName(
              restaurants.find((r) => r.id === e.target.value)?.name || ""
            );
            setSelectedCategory(""); // Reset category when changing restaurant
          }}
          disabled={isLoading || !restaurants.length}
        >
          {restaurants.map((restaurant) => (
            <MenuItem key={restaurant.id} value={restaurant.id}>
              {restaurant.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Search Field */}
      <TextField
        label="Search Menu Item"
        variant="outlined"
        value={searchKeyword}
        onChange={(e) => setSearchKeyword(e.target.value)}
        disabled={!restaurantId}
        style={{ width: "35%" }}
      />

      {/* Menu Items Table */}
      <Box sx={{ overflowX: "auto", mt: 3, width: "100%" }}>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]} // Options for rows per page
        component="div"
        count={filteredItems.length} // Total number of items after filtering
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{
          position: "sticky",
          right: 0,
          background: "#fff",
          zIndex: 1,
          padding: "3px",
        }}
      />
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              {[
                "Name",
                "Description",
                "Price",
                "Active",
                "Veg",
                "Recommended",
                "Variation",
                "Order Type",
                "Time",
                "Tags",
                "Image",
                "Actions",
              ].map((header) => (
                <th
                  key={header}
                  style={{
                    padding: "8px",
                    border: "1px solid #ddd",
                    textAlign: "left",
                    backgroundColor: "#f5f5f5",
                    position: header === "Actions" ? "sticky" : "static",
                    right: header === "Actions" ? 0 : "auto",
                    background: header === "Actions" ? "#fff" : "inherit",
                  }}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {paginatedItems.map((item) => (
              <React.Fragment key={item.id}>
                <tr>
                  <td>
                    <input
                      value={item.name}
                      onChange={(e) =>
                        handleFieldChange(item.id, "name", e.target.value)
                      }
                    />
                  </td>
                  <td onClick={() => toggleExpandDescription(item.id)}>
                  {expandedDescriptionId === item.id ? (
                    <textarea
                      ref={descriptionRef}
                      value={item.description || ""}
                      onChange={(e) =>
                        handleFieldChange(item.id, "description", e.target.value)
                      }
                      style={{ width: "100%", height: "100px" }}
                      onClick={(e) => e.stopPropagation()} // Prevents triggering handleClickOutside
                    />
                  ) : (
                    <input
                      value={item.description || ""}
                      onClick={() => toggleExpandDescription(item.id)}
                      style={{ width: "100%", cursor: "pointer" }}
                      readOnly
                    />
                  )}
                </td>

                  <td>
                    <input
                      value={item.price}
                      onChange={(e) =>
                        handleFieldChange(item.id, "price", e.target.value)
                      }
                      type="number"
                    />
                  </td>
                  <td>
                    <select
                      value={item.active ? "true" : "false"}
                      onChange={(e) =>
                        handleFieldChange(
                          item.id,
                          "active",
                          e.target.value === "true"
                        )
                      }
                    >
                      <option value="true">Active</option>
                      <option value="false">Inactive</option>
                    </select>
                  </td>
                  <td>
                    <select
                      value={item.isveg}
                      onChange={(e) =>
                        handleFieldChange(
                          item.id,
                          "isVeg",
                          e.target.value === "true"
                        )
                      }
                    >
                      <option value="true">Veg</option>
                      <option value="false">Non-Veg</option>
                    </select>
                  </td>
                  <td>
                  <select
                    value={item.isrecommend ? "true" : "false"}
                    onChange={(e) =>
                      handleFieldChange(item.id, "isrecommend", e.target.value === "true")
                    }
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>

                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={item.showVariations || false}
                          onChange={() =>
                            handleFieldChange(
                              item.id,
                              "showVariations",
                              !item.showVariations
                            )
                          }
                          disabled={
                            !item.variations || item.variations.length === 0
                          }
                        />
                      }
                      label="Show Variations"
                    />
                  </td>
                  <td>
                    <FormGroup
                      row
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr", // Two columns
                        alignItems: "center",
                        gap: "4px",
                        padding: "8px",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        backgroundColor: "#f9f9f9",
                      }}
                    >
                      {["Delivery", "Pick Up", "Dine In", "Online"].map(
                        (option) => (
                          <FormControlLabel
                            key={option}
                            control={
                              <Checkbox
                                size="small"
                                checked={item.ordertype.includes(option)}
                                onChange={() =>
                                  handleOrderTypeChange(item.id, option)
                                }
                                sx={{ padding: "4px" }}
                              />
                            }
                            label={option}
                            sx={{
                              fontSize: "0.7rem",
                              "& .MuiFormControlLabel-label": {
                                fontSize: "0.7rem",
                              },
                            }}
                          />
                        )
                      )}
                    </FormGroup>
                  </td>

                  <td>
                    <input
                      value={item.preparationtime || ""}
                      onChange={(e) =>
                        handleFieldChange(
                          item.id,
                          "preparationtime",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      value={item.tags ? JSON.stringify(item.tags) : ""}
                      onChange={(e) =>
                        handleFieldChange(
                          item.id,
                          "tags",
                          JSON.parse(e.target.value)
                        )
                      }
                    />
                  </td>
                  <td>
                    {item.image ? (
                      <a
                        href={item.image}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        style={{
                          fontSize: "12px",
                          textDecoration: "underline",
                        }}
                      >
                        Download Image
                      </a>
                    ) : (
                      <span style={{ fontSize: "12px", color: "#888" }}>
                        No image
                      </span>
                    )}
                    <input
                      type="file"
                      onChange={(e) =>
                        handleFieldChange(
                          item.id,
                          "imageFile",
                          e.target.files[0]
                        )
                      }
                      style={{
                        display: "block",
                        marginTop: "3px",
                        fontSize: "12px",
                      }}
                    />
                  </td>
                  <td
                    style={{
                      position: "sticky",
                      right: 0,
                      background: "#fff",
                      zIndex: 1,
                      padding: "3px",
                    }}
                  >
                    <div style={{ display: "flex", gap: "8px" }}>
                      <button
                        onClick={() => handleUpdate(item)}
                        disabled={isLoading}
                      >
                        Update
                      </button>
                      <button
                        onClick={() => handleDelete(item.id)}
                        disabled={isLoading}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
                {item.showVariations && (
                  <tr>
                    <td colSpan={12}>
                    {item.variations.map((variation, vIndex) => (
                        <div key={variation.id} style={{ marginBottom: "1rem", border: "1px solid #ddd", padding: "8px", borderRadius: "4px" }}>
                          <TextField
                            label="Variation Name"
                            value={variation.name}  
                            onChange={(e) =>
                              handleVariationChange(item.id, `variations.${vIndex}.name`, e.target.value)
                            }
                            fullWidth
                            style={{ marginBottom: "8px" }}
                          />
                          <TextField
                            label="Price"
                            type="number"
                            value={variation.price}  
                            onChange={(e) =>
                              handleVariationChange(item.id, `variations.${vIndex}.price`, e.target.value)
                            }
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={variation.default} 
                                onChange={() =>
                                  handleVariationChange(item.id, `variations.${vIndex}.default`, !variation.default)
                                }
                              />
                            }
                            label="Default"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={variation.isEnabled} 
                                onChange={() =>
                                  handleVariationChange(item.id, `variations.${vIndex}.isEnabled`, !variation.isEnabled)
                                }
                              />
                            }
                            label="Is Enabled"
                          />
                        </div>
                      ))}

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleVariationUpdate(item)}
                        disabled={isLoading}
                        style={{ marginTop: "8px" }}
                      >
                        Update Variations
                      </Button>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>

      </Box>
    </Box>
  );
};

export default GridComponent;
