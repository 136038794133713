import React, { useState, useEffect } from "react";
import { Box, FormControl, InputLabel, Select, MenuItem, TextField, Button, Typography, Paper, Grid, CircularProgress } from "@mui/material";
import axios from "axios";
import FileSaver from "file-saver";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";

const VariationIndicator = () => (<Box sx={{ position: "absolute", top: 5, right: 5, width: 20, height: 20, backgroundColor: "#ff9800", color: "#fff", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "12px", fontWeight: "bold" }}>V</Box>);

const Interaction = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState("");
  const [tableId, setTableId] = useState("");
  const [sessionData, setSessionData] = useState({});
  const [menuItemDetails, setMenuItemDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [feedbackData, setFeedbackData] = useState({
    diningExperience: [],
    followUpResponse: [],
  });
  const [selectedDate, setSelectedDate] = useState(null);


  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const response = await axios.get(
          "https://backend-cheeron.au-ki.com/onboard/restaurants"
        );
        setRestaurants(response.data);
        if (response.data.length > 0) {
          setSelectedRestaurant(response.data[0].id);
        }
      } catch (error) {
        console.error("Error fetching restaurants:", error);
      }
    };
    fetchRestaurants();
  }, []);

  const fetchSessions = async () => {
    if (!selectedRestaurant) {
      alert("Please select a restaurant.");
      return;
    }
  
    if (!selectedDate && !tableId) {
      alert("Table ID is mandatory if no date is selected.");
      return;
    }
  
    setIsLoading(true);
  
    try {
      const params = { restaurantId: selectedRestaurant };
      if (selectedDate) params.date = format(selectedDate, "yyyy-MM-dd");
      if (tableId) params.tableId = tableId;
  
      const response = await axios.get(
        "https://backend-cheeron.au-ki.com/interaction/getSessions",
        { params }
      );
  
      setSessionData(response.data);
  
      const feedback = await fetchFeedbackForSession(selectedRestaurant);
      setFeedbackData(feedback);
  
      const allMenuItemIds = extractMenuItemIds(response.data);
      if (allMenuItemIds.length > 0) {
        fetchMenuItems(selectedRestaurant, allMenuItemIds);
      }
  
      const sortedUsers = Object.entries(response.data)
        .filter(([userId, session]) => {
          if (!selectedDate) return true;
          return format(new Date(session.startTime), "yyyy-MM-dd") === format(selectedDate, "yyyy-MM-dd");
        })
        .sort((a, b) => new Date(b[1].startTime) - new Date(a[1].startTime));
  
      if (sortedUsers.length > 0) {
        setSelectedUserId(sortedUsers[0][0]);
      }
    } catch (error) {
      console.error("Error fetching session data:", error);
    }
  
    setIsLoading(false);
  };
  

  const extractMenuItemIds = (sessionData) => {
    if (!sessionData) return [];
  
    let menuItemIds = new Set();
  
    Object.values(sessionData).forEach((session) => {
      (session.context || []).forEach((msg) => {
        if (msg.role === "butler") {
          // Collect from arrays of IDs
          if (Array.isArray(msg.data)) {
            msg.data.forEach((id) => menuItemIds.add(id));
          }
  
          // Collect from repeat_order, view_order, pay_bill
          if (
            (msg.currentResType === "repeat_order" ||
              msg.type === "view_order" ||
              msg.type === "pay_bill") &&
            msg.data &&
            typeof msg.data === "object"
          ) {
            Object.keys(msg.data).forEach((id) => menuItemIds.add(id));
          }
        }
      });
    });
  
    return Array.from(menuItemIds);
  };
  


  const fetchMenuItems = async (restaurantId, menuItemIds) => {
    try {
      const response = await axios.get(
        `https://backend-cheeron.au-ki.com/restaurant-details/${restaurantId}`
      );
      const allMenuItems = response.data.menuItems || {};

      // Extract only relevant menu items
      const filteredMenuItems = {};
      menuItemIds.forEach((id) => {
        if (allMenuItems[id]) {
          filteredMenuItems[id] = allMenuItems[id];
        }
      });

      setMenuItemDetails((prev) => ({ ...prev, ...filteredMenuItems }));
    } catch (error) {
      console.error("Error fetching menu items:", error);
    }
  };

  const exportToJson = () => {
    if (!sessionData) {
      alert("No session data available to export.");
      return;
    }

    const dataToExport = JSON.stringify(sessionData, null, 2);
    const blob = new Blob([dataToExport], { type: "application/json" });
    FileSaver.saveAs(
      blob,
      `session_data_${selectedRestaurant}_${tableId}.json`
    );
  };

  const fetchFeedbackForSession = async (restaurantId) => {
    try {
      const [diningExperienceResponse, followUpResponse, menuResponse] = await Promise.all([
        axios.get("https://backend-cheeron.au-ki.com/feedback/getFeedBack", {
          params: { restaurantId, tableName: "dining_experience" },
        }),
        axios.get("https://backend-cheeron.au-ki.com/feedback/getFeedBack", {
          params: { restaurantId, tableName: "followup_response" },
        }),
        axios.get(`https://backend-cheeron.au-ki.com/restaurant-details/${restaurantId}`)
      ]);
  
      // Store menu item names for easy lookup
      const menuItems = menuResponse.data.menuItems || {};
  
      return {
        diningExperience: diningExperienceResponse.data || [],
        followUpResponse: followUpResponse.data.map((feedback) => {
          try {
            const parsedValue = JSON.parse(feedback.value);
            if (Array.isArray(parsedValue)) {
              feedback.value = parsedValue
                .map((id) => menuItems[id]?.name || id) // Replace with name if available
                .join(", ");
            }
          } catch (error) {
            // If parsing fails, keep the value as is
          }
          return feedback;
        }),
      };
    } catch (error) {
      console.error("Error fetching feedback data:", error);
      return { diningExperience: [], followUpResponse: [] };
    }
  };
  
  

  const sessionDetails = sessionData[selectedUserId] || null;

  const renderFeedbackSection = (feedbackArray, title, backgroundColor) => {
    const effectiveTableId = tableId || sessionData[selectedUserId]?.tableId; // Capture table ID from dropdown if not given
  
    const filteredFeedback = feedbackArray.filter(
      (feedback) =>
        feedback.rsid === selectedRestaurant &&
        (effectiveTableId ? feedback.tid === effectiveTableId : true) // Verify using the correct table ID
    );
  
    if (filteredFeedback.length === 0) {
      return (
        <Paper sx={{ padding: 2, backgroundColor, borderRadius: "5px", marginBottom: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>{title}</Typography>
          <Typography>No feedback available.</Typography>
        </Paper>
      );
    }
  
    return (
      <Paper sx={{ padding: 2, backgroundColor, borderRadius: "5px", marginBottom: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>{title}</Typography>
  
        {filteredFeedback.map((feedback, index) => {
          let parsedValue = feedback.value;
  
          // Handle JSON-string value cases
          try {
            const jsonParsed = JSON.parse(feedback.value);
            if (Array.isArray(jsonParsed)) {
              parsedValue = jsonParsed.join(", "); // Convert array to readable string
            }
          } catch (error) {
            // If parsing fails, keep the value as is
          }
  
          return (
            <Paper key={index} sx={{ padding: 2, backgroundColor: "#ffffff", borderRadius: "5px", marginBottom: 1 }}>
              <Typography sx={{ fontSize: "1.1em", fontWeight: "bold" }}>User: {feedback.user_name || "Unknown"}</Typography>
              <Typography sx={{ fontSize: "0.9em", color: "#757575" }}>Question: {feedback.question}</Typography>
              <Typography sx={{ fontSize: "1em", marginTop: 1, fontStyle: "italic" }}>{parsedValue}</Typography>
            </Paper>
          );
        })}
      </Paper>
    );
  };
  
  

  return (
    <Box sx={{ padding: 3, maxWidth: "900px", margin: "auto" }}>
      {/* Sticky Top Section */}
      <Box
        sx={{
          position: "sticky",
          top: 0,
          background: "#fff",
          zIndex: 1000,
          paddingBottom: 2,
        }}
      >
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel>Select Restaurant</InputLabel>
          <Select
            value={selectedRestaurant}
            onChange={(e) => setSelectedRestaurant(e.target.value)}
          >
            {restaurants.map((restaurant) => (
              <MenuItem key={restaurant.id} value={restaurant.id}>
                {restaurant.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Select Date"
            value={selectedDate}
            onChange={(newDate) => setSelectedDate(newDate)}
            renderInput={(params) => <TextField {...params} fullWidth sx={{ marginBottom: 2 }} />}
          />
        </LocalizationProvider>

        <TextField
          fullWidth
          label="Table ID"
          value={tableId}
          onChange={(e) => setTableId(e.target.value)}
          sx={{ marginBottom: 2 }}
        />

        {/* User Dropdown (Only shows if multiple users exist) */}
        {Object.keys(sessionData).length > 0 && (
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>Select User</InputLabel>
            <Select
  value={selectedUserId}
  onChange={(e) => {
    const newUserId = e.target.value;
    setSelectedUserId(newUserId);
    setTableId(sessionData[newUserId]?.tableId || ""); // Capture table ID from selected session
    fetchFeedbackForSession(selectedRestaurant, sessionData[newUserId]?.tableId || ""); // Fetch feedback for selected session
  }}
>

            {Object.entries(sessionData)
              .filter(([userId, session]) => {
                if (!selectedDate) return true; // Show all sessions if no date is selected
                return format(new Date(session.startTime), "yyyy-MM-dd") === format(selectedDate, "yyyy-MM-dd");
              })
              .map(([userId, session]) => {
                const sessionTimeIST = new Date(session.startTime).toLocaleString("en-IN", {
                  timeZone: "Asia/Kolkata",
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                });

                return (
                  <MenuItem key={userId} value={userId}>
                    {session.name || "Unknown User"} - {sessionTimeIST}
                    {!tableId && ` (Table: ${session.tableId})`}
                  </MenuItem>
                );
              })}

            </Select>
          </FormControl>
        )}

        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={fetchSessions}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Refresh"}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={exportToJson}
            disabled={!sessionData}
          >
            Export to JSON
          </Button>
        </Box>
      </Box>

      {/* Display Session Data */}
      {sessionDetails && (
        <Paper
          sx={{
            padding: 2,
            backgroundColor: "#f5f5f5",
            borderRadius: "5px",
            marginBottom: 2,
          }}
        >
          <Typography>
            <strong>Name:</strong> {sessionDetails.name || "N/A"}
          </Typography>
          <Typography>
            <strong>Phone Number:</strong> {sessionDetails.phoneNumber || "N/A"}
          </Typography>
          <Typography>
            <strong>Guest Count:</strong> {sessionDetails.guestCount || "N/A"}
          </Typography>
          <Typography>
            <strong>Guest Type:</strong> {sessionDetails.guestType || "N/A"}
          </Typography>
          <Typography>
            <strong>Visit Type:</strong> {sessionDetails.visitType || "N/A"}
          </Typography>
        </Paper>
      )}

      {sessionDetails?.context?.map((message, index) => (
        <Paper
          key={index}
          sx={{
            padding: 2,
            backgroundColor: "#fff",
            borderRadius: "5px",
            marginBottom: 2,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: "bold",
              color: message.role === "user" ? "#00796b" : "#d32f2f",
            }}
          >
            {message.role.toUpperCase()}
          </Typography>
          <Typography>{message.text}</Typography>

          {message.role === "butler" && (
            <>
              
              {message.type === "menu_inquiry" &&
                Array.isArray(message.data) &&
                message.data.length > 0 && (
                  <>
                    <Typography variant="h6" sx={{ marginTop: 1 }}>
                      Recommended Items
                    </Typography>
                    <Grid container spacing={2}>
                      {message.data.map((item) => {
                        let menuItem =
                          typeof item === "string"
                            ? menuItemDetails[item]
                            : item;
                        if (!menuItem) return null;

                        return (
                          <Grid item xs={6} sm={4} md={3} key={menuItem.id}>
                            <Paper
                              sx={{
                                padding: 1,
                                textAlign: "center",
                                height: "auto",
                                backgroundColor: "#e3f2fd",
                                position: "relative",
                              }}
                            >
                                  {menuItem.price === 0 && menuItem.variations && Object.keys(menuItem.variations).length > 0 && <VariationIndicator />}
                              <img
                                src={menuItem.image}
                                alt={menuItem.name}
                                style={{
                                  width: "100%",
                                  height: "120px",
                                  objectFit: "cover",
                                  borderRadius: "5px",
                                }}
                              />
                              <Typography
                                sx={{ fontWeight: "bold", marginTop: 1 }}
                              >
                                {menuItem.name}
                              </Typography>
                              {/* <Typography sx={{ fontSize: "0.9em", color: "#757575" }}>{menuItem.description}</Typography> */}
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  color: "#d32f2f",
                                  marginTop: 1,
                                }}
                              >
                                ₹ {parseFloat(menuItem.price).toFixed(2)}
                              </Typography>
                            </Paper>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </>
                )}


                {/* Handle Repeat Order */}
              {message.currentResType === "repeat_order" && message.data && (
                <>
                  <Typography variant="h6" sx={{ marginTop: 1 }}>
                    Repeat Order
                  </Typography>
                  <Typography sx={{ marginBottom: 1 }}>
                    Here is your past order, select the items you want to reorder.
                  </Typography>
                  <Grid container spacing={2}>
                    {Object.entries(message.data).map(([itemId, itemData]) => {
                      const menuItem = menuItemDetails[itemId]; // Fetch item details

                      if (!menuItem) return null;

                      return itemData.customizations.map((customization, index) => (
                        <Grid item xs={12} sm={6} md={4} key={`${itemId}-${index}`}>
                          <Paper
                            sx={{
                              padding: 2,
                              backgroundColor: "#e3f2fd",
                              borderRadius: "5px",
                              textAlign: "center",
                            }}
                          >

                          {menuItem.price === 0 && menuItem.variations && Object.keys(menuItem.variations).length > 0 && <VariationIndicator />}
                            <img
                              src={menuItem.image}
                              alt={menuItem.name}
                              style={{
                                width: "100%",
                                height: "120px",
                                objectFit: "cover",
                                borderRadius: "5px",
                              }}
                            />
                            <Typography sx={{ fontWeight: "bold", marginTop: 1 }}>
                              {menuItem.name}
                            </Typography>
                            <Typography>
                              Quantity: {customization.qty} x ₹{menuItem.price}
                            </Typography>
                          </Paper>
                        </Grid>
                      ));
                    })}
                  </Grid>
                </>
              )}

              {/* Display Ordered Menu Items */}
              {(message.type === "view_order" || message.type === "pay_bill") &&
                message.data &&
                typeof message.data === "object" &&
                (() => {
                  let subtotal = 0;

                  return (
                    <>
                      <Typography variant="h6" sx={{ marginTop: 1 }}>
                        {message.type === "pay_bill"
                          ? "Final Bill Details"
                          : "Ordered Items"}
                      </Typography>

                      <Grid container spacing={2}>
                        {Object.entries(message.data).map(
                          ([itemId, itemData]) => {
                            const item = menuItemDetails[itemId];
                            if (!item || !itemData.customizations) return null;

                            return itemData.customizations.map(
                              (customization, index) => {
                                const totalItemPrice = parseFloat(
                                  customization.price
                                );
                                subtotal += totalItemPrice; // Accumulate subtotal

                                return (
                                  <Grid
                                    item
                                    xs={12}
                                    key={`${item.id}-${index}`}
                                  >
                                    <Paper
                                      sx={{
                                        padding: 2,
                                        textAlign: "left",
                                        height: "auto",
                                        backgroundColor:
                                          message.type === "pay_bill"
                                            ? "#fff3e0"
                                            : "#e3f2fd",
                                      }}
                                    >
                                      <Typography sx={{ fontWeight: "bold" }}>
                                        {item.name}
                                      </Typography>
                                      <Typography>
                                        Quantity: {customization.qty}
                                      </Typography>
                                      <Typography>
                                        Price per item: ₹{" "}
                                        {parseFloat(
                                          customization.price /
                                            customization.qty
                                        ).toFixed(2)}
                                      </Typography>
                                      <Typography>
                                        <strong>Total Price:</strong> ₹{" "}
                                        {totalItemPrice.toFixed(2)}
                                      </Typography>
                                    </Paper>
                                  </Grid>
                                );
                              }
                            );
                          }
                        )}
                      </Grid>

                      {/* Subtotal Section */}
                      {message.type === "pay_bill" && (
                        <Box
                          sx={{
                            marginTop: 2,
                            padding: 2,
                            backgroundColor: "#ffecb3",
                            borderRadius: "5px",
                          }}
                        >
                          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                            Subtotal: ₹ {subtotal.toFixed(2)}
                          </Typography>
                        </Box>
                      )}
                    </>
                  );
                })()}
            </>
          )}
        </Paper>
      ))}
      {(feedbackData.diningExperience.length > 0 || feedbackData.followUpResponse.length > 0) && (
        <Box sx={{ marginTop: 4 }}>
          {renderFeedbackSection(feedbackData.diningExperience, "Dining Experience Feedback", "#e3f2fd")}
          {renderFeedbackSection(feedbackData.followUpResponse, "Follow-Up Response", "#ffebee")}
        </Box>
      )}

    </Box>
    
  );
};

export default Interaction;
