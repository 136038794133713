import React, { useState, useEffect } from 'react';
import { Box, Typography, FormControl, Select, MenuItem, Button, Container, CircularProgress, TextField, Switch, FormControlLabel } from '@mui/material';
import axios from 'axios';

const AvailabilityUpdate = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [categories, setCategories] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [restaurantId, setRestaurantId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [initialItems, setInitialItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [showUnavailableOnly, setShowUnavailableOnly] = useState(false); // Toggle state

  const fetchRestaurants = async () => {
    try {
      const response = await axios.get('https://backend-cheeron.au-ki.com/onboard/restaurants');
      setRestaurants(response.data);
      if (response.data.length > 0) {
        setRestaurantId(response.data[0].id);
      }
    } catch (error) {
      console.error('Error fetching restaurants.');
    }
  };

  const fetchMenuItems = async (restaurantId) => {
    setLoading(true);
    try {
      const response = await axios.get(`https://backend-cheeron.au-ki.com/onboard/items-restaurant?restaurantId=${restaurantId}`);
      setMenuItems(response.data);
      setFilteredItems(response.data); // Initially show all items
      setInitialItems(response.data.map(item => ({ ...item })));
    } catch (error) {
      console.error('Error fetching menu items:', error);
    } finally {
      setLoading(false);
    }
  };

  const filterMenuItems = () => {
    let result = menuItems;

    if (searchKeyword) {
      result = result.filter(item =>
        item.name.toLowerCase().includes(searchKeyword.toLowerCase())
      );
    }

    if (showUnavailableOnly) {
      result = result.filter(item => !item.active); // Show unavailable items only
    }

    if (categoryId) {
      result = result.filter(item => item.categoryId === categoryId);
    }

    setFilteredItems(result);
  };

  const handleAvailabilityChange = (itemId, checked) => {
    setFilteredItems(filteredItems.map(item => item.id === itemId ? { ...item, active: checked } : item));
  };

  const handleSubmit = async () => {
    const updatedItems = filteredItems.map(item => ({
      id: item.id,
      active: item.active
    }));
    try {
      await axios.put('https://backend-cheeron.au-ki.com/onboard/update-availability', { items: updatedItems });
      alert('Availability updated successfully!');
      setInitialItems(filteredItems.map(item => ({ ...item })));
    } catch (error) {
      console.error('Error updating availability:', error);
    }
  };

  const handleCancel = () => {
    setFilteredItems(initialItems.map(item => ({ ...item })));
  };

  useEffect(() => {
    fetchRestaurants();
  }, []);

  useEffect(() => {
    if (restaurantId) {
      fetchMenuItems(restaurantId);
    }
  }, [restaurantId]);

  useEffect(() => {
    filterMenuItems();
  }, [searchKeyword, showUnavailableOnly, categoryId]);

  return (
    <Container maxWidth="md">
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
        <FormControl sx={{ width: '40%' }}>
          <Select value={restaurantId} onChange={(e) => setRestaurantId(e.target.value)} displayEmpty sx={{ height: '40px', fontSize: '14px' }}>
            <MenuItem value="" disabled>Select Restaurant</MenuItem>
            {restaurants.map((restaurant) => (
              <MenuItem key={restaurant.id} value={restaurant.id}>
                {restaurant.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* <FormControl sx={{ width: '40%' }}>
          <Select value={categoryId} onChange={(e) => setCategoryId(e.target.value)} displayEmpty disabled={!restaurantId} sx={{ height: '40px', fontSize: '14px' }}>
            <MenuItem value="" disabled>Select Category</MenuItem>
            {categories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}

        <TextField
          label="Search Menu Items"
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          style={{ width: '40%' }}
          placeholder="Enter keyword to search"
          disabled={!restaurantId}
        />

        <FormControlLabel
          control={
            <Switch
              checked={showUnavailableOnly}
              onChange={(e) => setShowUnavailableOnly(e.target.checked)}
            />
          }
          label="Show Unavailable Items Only"
        />

        {loading ? (
          <CircularProgress />
        ) : (
          <Box sx={{ overflowX: 'auto', mt: 3, width: '75%' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <button onClick={handleSubmit} style={{ cursor: 'pointer', padding: '8px 16px', backgroundColor: 'green', color: 'white', border: 'none' }}>
                Update Availability
              </button>
              <button onClick={handleCancel} style={{ cursor: 'pointer', padding: '8px 16px', backgroundColor: 'gray', color: 'white', border: 'none' }}>
                Cancel
              </button>
            </Box>
            <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px', maxHeight:'70vh', overflow:'scroll' }}>
              <thead>
                <tr>
                  <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>Item Name</th>
                  <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>Available</th>
                </tr>
              </thead>
              <tbody>
                {filteredItems.map((item) => (
                  <tr key={item.id}>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{item.name}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>
                      <input
                        type="checkbox"
                        checked={item.active}
                        onChange={(e) => handleAvailabilityChange(item.id, e.target.checked)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

          </Box>
        )}
      </Box>
    </Container>
  );
};

export default AvailabilityUpdate;
