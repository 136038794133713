import React, { useState, useEffect } from 'react';
import { Container, Snackbar, Alert, Tabs, Tab, Button } from '@mui/material';
import axios from 'axios';
import GridComponent from './GridComponent';
import ExcelComponent from './ExcelComponent';

const MenuItemUpdate = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [restaurants, setRestaurants] = useState([]);
  const [restaurantId, setRestaurantId] = useState('');
  const [restaurantName, setRestaurantName] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [menuItems, setMenuItems] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const showAlert = (message) => {
    setAlertMessage(message);
    setOpenAlert(true);
  };

  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const response = await axios.get('https://backend-cheeron.au-ki.com/onboard/restaurants');
        setRestaurants(response.data);
        if (response.data.length > 0) {
          setRestaurantId(response.data[0].id);
          setRestaurantName(response.data[0].name);
        }
      } catch (error) {
        showAlert('Error fetching restaurants.');
      }
    };
    fetchRestaurants();
  }, []);

  useEffect(() => {
    if (restaurantId) {
      fetchCategories();
      fetchMenuItems();
    }
  }, [restaurantId, activeTab]);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`https://backend-cheeron.au-ki.com/onboard/categories?restaurantId=${restaurantId}`);
      setCategories(response.data);
    } catch (error) {
      showAlert('Error fetching categories!');
    }
  };

  const fetchMenuItems = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://backend-cheeron.au-ki.com/onboard/items-restaurant?restaurantId=${restaurantId}`);
      setMenuItems(response.data);
    } catch (error) {
      showAlert('Error fetching menu items!');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFieldChange = async (id, field, value) => {
    if (field === 'imageFile' && value) {
      // Create a FormData object for file upload
      const formData = new FormData();
      formData.append('image', value);
  
      try {
        // Replace with your S3 upload API endpoint
        const response = await axios.post('https://backend-cheeron.au-ki.com/update/upload-image', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          params: { folderName: 'menu_items' }
        });
        
        const imageUrl = response.data.imageUrl;
  
        setMenuItems((prevItems) =>
          prevItems.map((item) =>
            item.id === id ? { ...item, image: imageUrl } : item
          )
        );
  
        showAlert('Image uploaded and URL saved successfully!');
      } catch (error) {
        showAlert('Failed to upload image.');
      }
    } else {
      const processedValue =
        field === 'isVeg' || field === 'isrecommend' || field === 'active' || field === 'is_combo'
          ? JSON.parse(value)
          : field === 'tags' && typeof value === 'string'
          ? parseTags(value)
          : value;
  
      setMenuItems((prevItems) =>
        prevItems.map((item) =>
          item.id === id ? { ...item, [field]: processedValue } : item
        )
      );
    }
  };
  

  const parseTags = (value) => {
    try {
      return JSON.parse(value); // Parse if it's in JSON format
    } catch (e) {
      return []; // Return an empty array or set a default if parsing fails
    }
  };

  const handleUpdate = async (item) => {
    try {
      setIsLoading(true);
      const formattedItem = {
        ...item,
        ordertype: `{${item.ordertype.map((type) => `"${type}"`).join(',')}}`, // Format ordertype as PostgreSQL array
        tags: typeof item.tags === 'string' ? item.tags : JSON.stringify(item.tags), // Ensure tags is JSON
      };
      await axios.put('https://backend-cheeron.au-ki.com/update/update', formattedItem);
      showAlert('Menu item updated successfully!');
    } catch (error) {
      showAlert('Failed to update menu item.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('Are you sure you want to delete this item?')) return;

    try {
      await axios.delete(`https://backend-cheeron.au-ki.com/update/delete-items`, {
        params: { id },
      });

      // Update the menu items state by removing the deleted item
      setMenuItems((prevItems) => prevItems.filter((item) => item.id !== id));
      showAlert('Menu item deleted successfully!');
    } catch (error) {
      showAlert('Failed to delete menu item.');
    }
  };


  const handleCloseAlert = () => setOpenAlert(false);

  const fetchAndDownloadExcel = async () => {
    if (!restaurantId) {
      showAlert("Please select a restaurant");
      return;
    }
  
    setIsLoading(true);
  
    // Find the name of the currently selected restaurant based on restaurantId
    const selectedRestaurantName = restaurants.find(r => r.id === restaurantId)?.title.replace(/[^a-zA-Z0-9]/g, '_');
  
    try {
      const response = await axios.get(`https://backend-cheeron.au-ki.com/update/menu-items/no-image`, {
        params: { restaurantId },
        responseType: 'blob',
      });
  
      const timestamp = Date.now();
      const fileName = `imageless_${selectedRestaurantName || 'restaurant'}_${timestamp}.xlsx`;
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
  
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
  
      showAlert('Excel file downloaded successfully!');
    } catch (error) {
      showAlert('Error generating Excel file.');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleBulkSave = async () => {
    try {
      setIsLoading(true);
      const formattedItems = menuItems.map((item) => ({
        ...item,
        ordertype: `{${item.ordertype.map((type) => `"${type}"`).join(',')}}`,
        tags: typeof item.tags === 'string' ? item.tags : JSON.stringify(item.tags), // Ensure tags is JSON
      }));
      await axios.put('https://backend-cheeron.au-ki.com/update/bulk-update', { items: formattedItems });
      showAlert('All menu items updated successfully!');
    } catch (error) {
      showAlert('Failed to update all menu items.');
    } finally {
      setIsLoading(false);
    }
  };

  const updateNestedField = (obj, fieldPath, value) => {
    const keys = fieldPath.split('.');
    const lastKey = keys.pop();
    const nestedObj = keys.reduce((acc, key) => acc[key], obj);
    nestedObj[lastKey] = value;
  };
  
  const handleVariationChange = (id, field, value) => {
    setMenuItems((prevItems) =>
      prevItems.map((item) => {
        if (item.id === id) {
          const updatedVariations = [...item.variations];
          
          // Extract variation index and field name
          const match = field.match(/variations\.(\d+)\.(.+)/);
          if (!match) return item; // Skip if field pattern is incorrect
  
          const variationIndex = Number(match[1]); // Extract variation index
          const variationField = match[2]; // Extract field name inside variation
  
          // Update the specific field inside the variation
          updatedVariations[variationIndex] = {
            ...updatedVariations[variationIndex],
            [variationField]: value,
          };
  
          return { ...item, variations: updatedVariations };
        }
        return item;
      })
    );
  };
  
  
  const handleVariationUpdate = async (item) => {
    try {
      setIsLoading(true);
  
      // Format variations as per new schema
      const formattedVariations = item.variations.map((variation) => ({
        id: variation.id,
        name: variation.name,
        price: Number(variation.price),
        default: !!variation.default,
        isEnabled: !!variation.isEnabled,
      }));
  
      await axios.put('https://backend-cheeron.au-ki.com/onboard/variation-update', {
        id: item.id,
        variations: formattedVariations,  
      });

      showAlert('Variations updated successfully!');
    } catch (error) {
      console.error('Error updating variations:', error);
      showAlert('Failed to update variations.');
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <Container maxWidth="xxl">
      <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)} centered>
        <Tab label="Excel" />
        <Tab label="Grid" />
      </Tabs>

      {activeTab === 0 && (
        <ExcelComponent
          restaurantId={restaurantId}
          restaurantName={restaurantName}
          setRestaurantId={setRestaurantId}
          restaurants={restaurants}
          showAlert={showAlert}
          isLoading={isLoading}
          fetchAndDownloadExcel={fetchAndDownloadExcel}
        />
      )}

      {activeTab === 1 && (
                <>
          <Button variant="contained" color="primary" onClick={handleBulkSave} disabled={isLoading}>
            Save All
          </Button>
        <GridComponent
          restaurants={restaurants}
          restaurantId={restaurantId}
          setRestaurantId={setRestaurantId}
          restaurantName={restaurantName}
          setRestaurantName={setRestaurantName}
          categories={categories}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          menuItems={menuItems}
          setMenuItems={setMenuItems}
          isLoading={isLoading}
          handleFieldChange={handleFieldChange}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
          showAlert={showAlert}
          fetchMenuItems={fetchMenuItems} // Add this prop
          handleVariationChange={handleVariationChange}
          handleVariationUpdate={handleVariationUpdate}

        />
        </>
      )}

      <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleCloseAlert} severity="info" sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default MenuItemUpdate;
  