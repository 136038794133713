import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, Switch, Tabs, Tab, TextField } from '@mui/material';
import axios from 'axios';
import * as XLSX from 'xlsx';

const AddRecommendations = ({ restaurantId }) => {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const [changes, setChanges] = useState({});
  const [activeSubTab, setActiveSubTab] = useState(0); // 0 = Add via Grid, 1 = Add via Excel
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (restaurantId) {
      fetchMenuItems(restaurantId);
    }
  }, [restaurantId]);

  useEffect(() => {
    if (searchKeyword) {
      setFilteredItems(items.filter(item => item.name.toLowerCase().includes(searchKeyword.toLowerCase())));
    } else {
      setFilteredItems(items);
    }
  }, [searchKeyword, items]);

  const fetchMenuItems = async (restaurantId) => {
    setLoading(true);
    try {
      const response = await axios.get(`https://backend-cheeron.au-ki.com/onboard/items-restaurant?restaurantId=${restaurantId}`);
      const nonRecommendedItems = response.data.filter(item => !item.isrecommend);
      setItems(nonRecommendedItems);
      setFilteredItems(nonRecommendedItems.map(item => ({ ...item })));
    } catch (error) {
      console.error('Error fetching menu items:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (itemId, checked) => {
    setFilteredItems(filteredItems.map(item => item.id === itemId ? { ...item, isrecommend: checked } : item));

    setChanges(prev => ({
      ...prev,
      [itemId]: { ...prev[itemId], isrecommend: checked }
    }));
  };

  const handleRankChange = (itemId, value) => {
    setFilteredItems(filteredItems.map(item => item.id === itemId ? { ...item, rank: value } : item));

    setChanges(prev => ({
      ...prev,
      [itemId]: { ...prev[itemId], rank: value }
    }));
  };

  const handleAdd = async () => {
    if (Object.keys(changes).length === 0) {
      alert('No changes detected.');
      return;
    }
  
    try {
      await axios.put('https://backend-cheeron.au-ki.com/recommend/update-recommend', { 
        restaurantId, 
        updates: changes 
      });
      alert('Recommendations added successfully!');
      setChanges({});
    } catch (error) {
      console.error('Error adding recommendations:', error);
      alert('Failed to add recommendations.');
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const processExcel = async () => {
    if (!file) {
      alert("Please select an Excel file.");
      return;
    }

    setIsUploading(true);

    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      // Validate and structure data
      const formattedData = jsonData.map(row => ({
        id: row.id ? String(row.id).trim() : null,
        name: row.name ? String(row.name).trim().toLowerCase() : null,
        isRecommend: row.isRecommend?.toString().toLowerCase() === "true",
        rank: row.rank ? parseInt(row.rank, 10) : null
      }));

      try {
        const response = await axios.post('https://backend-cheeron.au-ki.com/recommend/upload-excel', {
          restaurantId,
          updates: formattedData
        });

        alert(response.data.message);
      } catch (error) {
        console.error('Error uploading recommendations:', error);
        alert("Failed to process the Excel file.");
      } finally {
        setIsUploading(false);
      }
    };

    reader.readAsArrayBuffer(file);
  };
  

  return (
    <Box sx={{ mt: 3 }}>
      {/* Sub-tabs for Add via Grid & Add via Excel */}
      <Tabs
        value={activeSubTab}
        onChange={(e, newValue) => setActiveSubTab(newValue)}
        centered
        textColor="inherit"
        indicatorColor="secondary"
        sx={{ marginBottom: 2 }}
      >
        <Tab label="Add through Grid" />
        <Tab label="Add through Excel" />
      </Tabs>

      {/* Add through Grid */}
      {activeSubTab === 0 && (
        <>
          <TextField
            label="Search Menu Items"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            sx={{ width: '100%', mt: 2 }}
            placeholder="Enter keyword to search"
          />

          <Button variant="contained" color="primary" sx={{ width: '30%', mt: 2 }} onClick={handleAdd}>
            Add Recommendations
          </Button>

          {loading ? (
            <CircularProgress />
          ) : (
            <Box sx={{ overflowY: 'auto', mt: 3, height: '65vh' }}>
              <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
                <thead>
                  <tr>
                    <th style={{ padding: '5px', border: '1px solid #ddd', textAlign: 'left' }}>Item Name</th>
                    <th style={{ padding: '5px', border: '1px solid #ddd', textAlign: 'center' }}>Recommend</th>
                    <th style={{ padding: '5px', border: '1px solid #ddd', textAlign: 'center' }}>Rank</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredItems.map((item) => (
                    <tr key={item.id}>
                      <td style={{ padding: '5px', border: '1px solid #ddd' }}>{item.name}</td>
                      <td style={{ padding: '5px', border: '1px solid #ddd', textAlign: 'center' }}>
                        <Switch
                          checked={item.isrecommend}
                          onChange={(e) => handleCheckboxChange(item.id, e.target.checked)}
                        />
                      </td>
                      <td style={{ padding: '5px', border: '1px solid #ddd', textAlign: 'center' }}>
                        <TextField
                          type="number"
                          value={item.rank}
                          onChange={(e) => handleRankChange(item.id, e.target.value)}
                          sx={{ width: '80px' }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          )}
        </>
      )}
    {activeSubTab === 1 && (
        <Box sx={{ mt: 4 }}>
          <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} /><br />
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={processExcel}
            disabled={isUploading}
          >
            {isUploading ? <CircularProgress size={24} /> : "Upload & Process"}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default AddRecommendations;
