import React, { useState, useEffect } from 'react';
import { Box, Typography,ThemeProvider, FormControl,createTheme,Tabs,Tab, FormControlLabel, Switch, InputLabel, TextField, Select, MenuItem, Container, Snackbar, Alert, Modal } from '@mui/material';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import * as XLSX from 'xlsx';
import AddCategory from './AddCategory';
import SubCategoryManagement from './SubCategoryManagement';


const theme = createTheme({
  palette: {
    primary: { main: '#1976d2' },
    secondary: { main: '#f50057' },
    background: { default: '#f5f5f5' },
  },
  typography: {
    h4: { fontWeight: 600, marginBottom: '20px' },
    body1: { color: '#4a4a4a' },
  },
});

const CategoryManagement = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [restaurants, setRestaurants] = useState([]), [restaurantId, setRestaurantId] = useState(''), [categories, setCategories] = useState([]), [filteredCategories, setFilteredCategories] = useState([]), [menuItemsMap, setMenuItemsMap] = useState({}), [allMenuItems, setAllMenuItems] = useState([]), [alertMessage, setAlertMessage] = useState(''), [openAlert, setOpenAlert] = useState(false), [isLoading, setIsLoading] = useState(false), [selectedCategory, setSelectedCategory] = useState(null), [isViewModalOpen, setIsViewModalOpen] = useState(false), [isAddModalOpen, setIsAddModalOpen] = useState(false), [modalSearch, setModalSearch] = useState(''), [categorySearch, setCategorySearch] = useState(''), [selectedItems, setSelectedItems] = useState([]), [showCategoryId, setShowCategoryId] = useState(false);

  const showAlert = (message) => { setAlertMessage(message); setOpenAlert(true); };

  const fetchRestaurants = async () => {
    try { const res = await axios.get('https://backend-cheeron.au-ki.com/onboard/restaurants'); setRestaurants(res.data); if (res.data.length) setRestaurantId(res.data[0].id); }
    catch { showAlert('Error fetching restaurants.'); }
  };

  const fetchCategories = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(`https://backend-cheeron.au-ki.com/onboard/categories?restaurantId=${restaurantId}`);
      const itemIds = res.data.flatMap(c => c.items);
      fetchMenuItemsByIds(itemIds);
      setCategories(res.data);
      setFilteredCategories(res.data);
    } catch { showAlert('Error fetching categories.'); } finally { setIsLoading(false); }
  };

  const fetchMenuItemsByIds = async (itemIds) => {
    try {
      const res = await axios.post('https://backend-cheeron.au-ki.com/onboard/items-by-ids', { itemIds });
      const itemMap = {}; res.data.forEach(i => { itemMap[i.id] = i.name; });
      setMenuItemsMap(itemMap);
    } catch { console.error('Error fetching menu items by IDs'); }
  };

  const fetchAllMenuItems = async () => {
    try { const res = await axios.get(`https://backend-cheeron.au-ki.com/onboard/items-restaurant?restaurantId=${restaurantId}`); setAllMenuItems(res.data); }
    catch { showAlert('Error fetching all menu items for restaurant.'); }
  };

  const handleUpdateCategory = async (id, title, items, active, sort_order, displayImage, hassubcategory) => {
    try {
      await axios.put('https://backend-cheeron.au-ki.com/update/update-category', {
        id,
        title,
        items,
        active,
        sort_order,
        displayImage, // Include the display image URL
        hassubcategory,
      });
      showAlert('Category updated successfully.');
      fetchCategories(); // Refresh categories
    } catch {
      showAlert('Failed to update category.');
    }
  };
  

  const handleChange = (id, field, value) => {
    setCategories(categories.map(c => c.id === id ? { ...c, [field]: value } : c));
    if (selectedCategory && selectedCategory.id === id) setSelectedCategory(prev => ({ ...prev, [field]: value }));
  };

  const handleRemoveItem = (itemId) => { setSelectedCategory(prev => ({ ...prev, items: prev.items.filter(id => id !== itemId) })); };

  const toggleSelectItem = (itemId) => { setSelectedItems(prev => prev.includes(itemId) ? prev.filter(id => id !== itemId) : [...prev, itemId]); };

  useEffect(() => { fetchRestaurants(); }, []);
  useEffect(() => { if (restaurantId) fetchCategories(); }, [restaurantId]);
  useEffect(() => {
    setFilteredCategories(categories.filter(c => c.title.toLowerCase().includes(categorySearch.toLowerCase())));
  }, [categorySearch, categories]);

  const availableMenuItems = allMenuItems.filter(item => !selectedCategory?.items.includes(item.id));

  const handleImageUpload = async (e, categoryId) => {
    const file = e.target.files[0];
    if (!file) return;
  
    const formData = new FormData();
    formData.append('image', file);
  
    try {
      // Upload image
      const response = await axios.post('https://backend-cheeron.au-ki.com/update/upload-image', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
  
      const imageUrl = response.data.imageUrl;
  
      // Update the category with the new image URL
      const updatedCategories = categories.map((c) =>
        c.id === categoryId ? { ...c, display_image: imageUrl } : c
      );
      setCategories(updatedCategories);
  
      showAlert('Image uploaded successfully!');
    } catch (error) {
      console.error('Error uploading image:', error);
      showAlert('Failed to upload image.');
    }
  };

  const handleImageUploadToS3 = async () => {
    if (!selectedItems.length) {
      showAlert('Please select images to upload.');
      return;
    }
  
    const formData = new FormData();
    Array.from(selectedItems).forEach((file) => formData.append('files', file)); // Match 'files' with upload.array('files')
  
    try {
      const response = await axios.post('https://backend-cheeron.au-ki.com/update/upload-folder', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        responseType: 'blob', // Important for downloading the Excel file
      });
  
      // Download the Excel file
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `UploadResults_${Date.now()}.xlsx`;
      link.click();
  
      showAlert('Images uploaded and Excel downloaded successfully!');
    } catch (error) {
      console.error('Error uploading images or creating Excel:', error);
      showAlert('Failed to upload images or create Excel.');
    }
  };
  
  

  const handleExcelUpload = async (e) => {
    const file = e.target.files[0];
    if (!file || !restaurantId) {
      showAlert('Please select a valid Excel file and restaurant.');
      return;
    }
  
    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const rows = XLSX.utils.sheet_to_json(sheet);
  
      // Prepare payload for updating database
      const payload = rows.map((row) => ({
        title: row.name,
        displayImage: row.imageUrl,
      }));
  
      await axios.post('https://backend-cheeron.au-ki.com/onboard/update-Category-images', { restaurantId, data: payload });
  
      showAlert('Database updated successfully!');
    } catch (error) {
      console.error('Error processing Excel or updating database:', error);
      showAlert('Failed to process Excel or update database.');
    }
  };

  return (
    <ThemeProvider theme={theme}>
        <Tabs
      value={activeTab}
      onChange={(e, newValue) => setActiveTab(newValue)}
      centered
    >
      <Tab label="Management" />
      <Tab label="Category Image" />
      <Tab label="Add Category" />
      <Tab label="Sub Category" />
    </Tabs>
    <Container maxWidth="70%" >

      {activeTab === 0 && (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
        <FormControl sx={{ width: '40%' }}>
          <InputLabel>Restaurant</InputLabel>
          <Select label="Restaurant" value={restaurantId} onChange={(e) => setRestaurantId(e.target.value)} disabled={!restaurants.length} sx={{ height: '40px', fontSize: '14px' }}>
            {restaurants.map(r => <MenuItem key={r.id} value={r.id}>{r.title}</MenuItem>)}
          </Select>
        </FormControl>
        <TextField label="Search Categories" variant="outlined" value={categorySearch} onChange={(e) => setCategorySearch(e.target.value)} sx={{ width: '40%' }} />
        <FormControlLabel control={<Switch checked={showCategoryId} onChange={(e) => setShowCategoryId(e.target.checked)} />} label="Show Category ID" />

        {isLoading ? (<Typography>Loading...</Typography>) : (
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px', textAlign: 'left' }}>
            <thead>
              <tr>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Title</th>
                {showCategoryId && <th style={{ padding: '8px', border: '1px solid #ddd' }}>Category ID</th>}
                <th style={{ padding: '8px', border: '1px solid #ddd', maxWidth: '20px' }}>Sort Order</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Has Subcategory</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Items</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Active</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Image</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredCategories.map(c => (
                <tr key={c.id}>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}><input type="text" value={c.title} onChange={(e) => handleChange(c.id, 'title', e.target.value)} style={{ width: '100%' }} /></td>
                  {showCategoryId && <td style={{ padding: '8px', border: '1px solid #ddd', fontSize: '11px' }}>{c.id}</td>}
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}><input type="number" value={c.sort_order} onChange={(e) => handleChange(c.id, 'sort_order', e.target.value)} style={{ width: '100%' }} /></td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                    <select
                      value={c.hassubcategory}
                      onChange={(e) => handleChange(c.id, 'hassubcategory', e.target.value === 'true')}
                      style={{ width: '100%' }}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                    <button onClick={() => setSelectedCategory(c) || setIsViewModalOpen(true)} style={{ cursor: 'pointer' }}>Remove Items</button>
                    <button onClick={() => setSelectedCategory(c) || setIsAddModalOpen(true) || fetchAllMenuItems()} style={{ cursor: 'pointer', marginLeft: '5px' }}>Add Items</button>
                  </td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                    <select value={c.active} onChange={(e) => handleChange(c.id, 'active', e.target.value === 'true')} style={{ width: '100%' }}>
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </select>
                  </td>
                  <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>
                    {c.display_image ? (
                      <>
                        <a
                          onClick={() => window.open(c.display_image, '_blank')}
                          style={{
                            color: 'blue'
                          }}
                        >
                          View Image
                        </a>
                      </>
                    ) : (
                      <span>No image</span>
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleImageUpload(e, c.id)}
                      style={{
                        marginTop: c.display_image ? '5px' : '0',
                        display: 'block',
                      }}
                    />
                  </td>
                  <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>
                    <button
                      onClick={() =>
                        handleUpdateCategory(c.id, c.title, c.items, c.active, c.sort_order, c.display_image,c.hassubcategory)
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      Update
                    </button>
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Box>
      )}

    {activeTab === 1 && (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
        {/* Select Restaurant */}
        <FormControl sx={{ width: '40%' }}>
          <InputLabel>Restaurant</InputLabel>
          <Select
            value={restaurantId}
            onChange={(e) => setRestaurantId(e.target.value)}
            disabled={!restaurants.length}
            sx={{ height: '40px', fontSize: '14px' }}
          >
            {restaurants.map((r) => (
              <MenuItem key={r.id} value={r.id}>
                {r.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Upload Images */}
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={(e) => setSelectedItems(e.target.files)}
        />
        <button
          onClick={handleImageUploadToS3}
          style={{ cursor: 'pointer', padding: '8px 16px', backgroundColor: 'blue', color: 'white', border: 'none', width: '30%' }}
        >
          Upload and Download Excel
        </button>

        {/* Upload Excel for Update */}
        <input
          type="file"
          accept=".xlsx"
          onChange={(e) => handleExcelUpload(e)}
        />
      </Box>
    )}

    {activeTab === 2 && <AddCategory />}
    {activeTab === 3 && <SubCategoryManagement />}


      {/* View Items Modal */}
      <Modal open={isViewModalOpen} onClose={() => setIsViewModalOpen(false)}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, maxHeight: '80%', overflowY: 'scroll', bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
          <Typography variant="h6" component="h2">{selectedCategory?.title} - Items</Typography>
          <TextField label="Search Items in Category" variant="outlined" value={modalSearch} onChange={(e) => setModalSearch(e.target.value)} fullWidth sx={{ mb: 2 }} />
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {selectedCategory?.items.filter(itemId => menuItemsMap[itemId]?.toLowerCase().includes(modalSearch.toLowerCase())).map(itemId => (
              <button key={itemId} style={{ display: 'flex', alignItems: 'center', padding: '5px', border: '1px solid #ddd', cursor: 'pointer', margin: '3px' }}>
                <span>{menuItemsMap[itemId]}</span>
                <CloseIcon fontSize="small" style={{ marginLeft: '5px' }} onClick={() => handleRemoveItem(itemId)} />
              </button>
            ))}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
            <button onClick={() => handleUpdateCategory(selectedCategory.id, selectedCategory.title, selectedCategory.items, selectedCategory.active, selectedCategory.sort_order) || setIsViewModalOpen(false)} style={{ cursor: 'pointer', padding: '8px 16px', backgroundColor: 'blue', color: 'white', border: 'none' }}>Update</button>
            <button onClick={() => setIsViewModalOpen(false)} style={{ cursor: 'pointer', padding: '8px 16px', backgroundColor: 'gray', color: 'white', border: 'none' }}>Close</button>
          </Box>
        </Box>
      </Modal>

      {/* Add Items Modal */}
      <Modal open={isAddModalOpen} onClose={() => setIsAddModalOpen(false)}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, maxHeight: '80%', overflowY: 'scroll', bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
          <Typography variant="h6" component="h2">Add Items to {selectedCategory?.title}</Typography>
          <TextField label="Search Items" variant="outlined" value={modalSearch} onChange={(e) => setModalSearch(e.target.value)} fullWidth sx={{ mb: 2 }} />
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {availableMenuItems.filter(item => item.name.toLowerCase().includes(modalSearch.toLowerCase())).map(item => (
              <button key={item.id} onClick={() => toggleSelectItem(item.id)} style={{ display: 'flex', alignItems: 'center', padding: '5px', border: '1px solid #ddd', cursor: 'pointer', margin: '3px', backgroundColor: selectedItems.includes(item.id) ? 'lightblue' : 'white' }}>
                <span>{item.name}</span>
                {selectedItems.includes(item.id) && <CloseIcon fontSize="small" style={{ marginLeft: '5px' }} />}
              </button>
            ))}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
            <button onClick={() => handleUpdateCategory(selectedCategory.id, selectedCategory.title, [...selectedCategory.items, ...selectedItems], selectedCategory.active, selectedCategory.sort_order) || setIsAddModalOpen(false)} style={{ cursor: 'pointer', padding: '8px 16px', backgroundColor: 'blue', color: 'white', border: 'none' }}>Add</button>
            <button onClick={() => setIsAddModalOpen(false)} style={{ cursor: 'pointer', padding: '8px 16px', backgroundColor: 'gray', color: 'white', border: 'none' }}>Cancel</button>
          </Box>
        </Box>
      </Modal>

      <Snackbar open={openAlert} autoHideDuration={3000} onClose={() => setOpenAlert(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={() => setOpenAlert(false)} severity="info" sx={{ width: '100%' }}>{alertMessage}</Alert>
      </Snackbar>
    </Container>
    </ThemeProvider>
  );
};

export default CategoryManagement;
