import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Paper,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const OrderData = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState("");
  const [orders, setOrders] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dateError, setDateError] = useState("");
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const [totalOrderValue, setTotalOrderValue] = useState(0);

  // Fetch restaurants on mount.
  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const response = await axios.get("https://backend-cheeron.au-ki.com/onboard/restaurants");
        setRestaurants(response.data);
        if (response.data.length > 0) {
          setSelectedRestaurant(response.data[0].id);
        }
      } catch (error) {
        console.error("Error fetching restaurants:", error);
      }
    };
    fetchRestaurants();
  }, []);

  // Validate date range.
  useEffect(() => {
    if (fromDate && toDate && fromDate > toDate) {
      setDateError("From Date should be earlier than To Date.");
    } else {
      setDateError("");
    }
  }, [fromDate, toDate]);

  // Helper to format a Date to YYYY-MM-DD.
  const formatLocalDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Fetch order data from the backend endpoint.
  const fetchOrderData = async () => {
    setLoading(true);
    try {
      const params = { restaurantId: selectedRestaurant };
      if (fromDate && toDate) {
        params.fromDate = formatLocalDate(fromDate);
        params.toDate = formatLocalDate(toDate);
      }
      const response = await axios.get("https://backend-cheeron.au-ki.com/data/order-data", { params });
      const data = response.data;
      // Assume the backend returns: orders (array), totalOrders, totalOrderValue.
      setOrders(data.orders || []);
      setTotalOrdersCount(data.totalOrders || 0);
      setTotalOrderValue(data.totalOrderValue || 0);
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
    setLoading(false);
  };

  // Render an accordion for each order.
  const renderOrderAccordion = (order) => {
    return (
      <Accordion key={order.orderId} sx={{ mb: 2 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle1">
              Order ID: {order.orderId} | Table ID: {order.tableId}
            </Typography>
            <Typography variant="body2">
              Created: {order.createdAt} | Completed: {order.completedAt}
            </Typography>
            <Typography variant="body2">
              Order Total: ₹{order.orderTotal}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {order.items && order.items.length > 0 ? (
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Item Name</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Price (₹)</TableCell>
                    <TableCell>Variation</TableCell>
                    <TableCell>Addons</TableCell>
                    <TableCell align="right">Item Total (₹)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.items.map((item, idx) => {
                    // Format addons as a comma-separated list.
                    const addonsStr = item.addons && item.addons.length > 0 
                      ? item.addons.map(a => `${a.addonName} (₹${a.addonPrice})`).join(", ")
                      : "-";
                    return (
                      <TableRow key={idx}>
                        <TableCell>{item.itemName}</TableCell>
                        <TableCell align="right">{item.quantity}</TableCell>
                        <TableCell align="right">{item.price}</TableCell>
                        <TableCell>{item.variation || "-"}</TableCell>
                        <TableCell>{addonsStr}</TableCell>
                        <TableCell align="right">{item.itemTotal}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No item details available for this order.</Typography>
          )}
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Order Data
      </Typography>
      {/* Filters */}
      <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mb: 2, alignItems: "center" }}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Restaurant</InputLabel>
          <Select
            value={selectedRestaurant}
            label="Restaurant"
            onChange={(e) => setSelectedRestaurant(e.target.value)}
          >
            {restaurants.map((rest) => (
              <MenuItem key={rest.id} value={rest.id}>
                {rest.title || rest.id}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="From Date"
            value={fromDate}
            onChange={(newDate) => setFromDate(newDate)}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            label="To Date"
            value={toDate}
            onChange={(newDate) => setToDate(newDate)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        {dateError && <Typography color="error">{dateError}</Typography>}
        <Button
          variant="contained"
          onClick={fetchOrderData}
          disabled={!selectedRestaurant || !fromDate || !toDate || !!dateError}
        >
          Fetch Order Data
        </Button>
      </Box>
      {/* Display Totals */}
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="subtitle1">
          Total Orders: {totalOrdersCount} | Total Order Value: ₹{totalOrderValue.toFixed(2)}
        </Typography>
      </Paper>
      {/* Display Order Accordions */}
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : orders && orders.length > 0 ? (
        orders.map((order) => renderOrderAccordion(order))
      ) : (
        <Typography>No orders available for the selected period.</Typography>
      )}
    </Box>
  );
};

export default OrderData;
