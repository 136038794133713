import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Paper,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  CircularProgress,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// Updated date formatter to use IST and AM/PM format.
const formatDateTime = (dt) =>
  new Date(dt).toLocaleString("en-US", { timeZone: "Asia/Kolkata", hour12: true });

/* ------------------ Inner Session Accordion ------------------ */
const SessionAccordion = ({ session, expanded, onChange, menuItems }) => {
  // Calculate cart total.
  const cartTotal = session.session_data.cart
    ? Object.values(session.session_data.cart).reduce((sum, item) => {
      if (item.customizations && Array.isArray(item.customizations)) {
        return (
          sum +
          item.customizations.reduce((s, cust) => s + cust.qty * cust.price, 0)
        );
      }
      return sum;
    }, 0)
    : 0;

  return (
    <Accordion expanded={expanded} onChange={onChange} sx={{ mb: 1 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle2">
          Session ID: {session.id} | Start: {formatDateTime(session.start_time)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {/* Session Details */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2">
            <strong>Close Time:</strong> {formatDateTime(session.close_time)}
          </Typography>
          <Typography variant="body2">
            <strong>Guest Type:</strong>{" "}
            {session.session_data.guestType || "N/A"}
          </Typography>
          <Typography variant="body2">
            <strong>Visit Type:</strong>{" "}
            {session.session_data.visitType || "N/A"}
          </Typography>
          <Typography variant="body2">
            <strong>Phone:</strong>{" "}
            {session.session_data.phoneNumber || "N/A"}
          </Typography>
          <Typography variant="body2">
            <strong>Guest Name:</strong>{" "}
            {session.session_data.name || "N/A"}
          </Typography>
          <Typography variant="body2">
            <strong>How Did You Hear:</strong>{" "}
            {session.session_data.howDidYouHear || "N/A"}
          </Typography>
          <Typography variant="body2">
            <strong>Guest Count:</strong>{" "}
            {session.session_data.guestCount || "N/A"}
          </Typography>
          <Typography variant="body2">
            <strong>OTP Verified:</strong> {String(session.otpVerified)}
          </Typography>

        </Box>
        {/* Cart Details as Table (only if cart data exists) */}
        {session.session_data.cart && Object.keys(session.session_data.cart).length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Cart Details (Total: ₹{cartTotal.toFixed(2)})
            </Typography>
            <TableContainer component={Paper} variant="outlined">
              <Table size="small">
                <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
                  <TableRow>
                    <TableCell>Item Name</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Price (₹)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(session.session_data.cart).map(
                    ([itemId, item]) => {
                      const itemName =
                        item.name || menuItems[itemId] || itemId;
                      return item.customizations &&
                        Array.isArray(item.customizations) ? (
                        item.customizations.map((cust, idx) => (
                          <TableRow key={`${itemId}-${idx}`}>
                            <TableCell>{itemName}</TableCell>
                            <TableCell align="right">{cust.qty}</TableCell>
                            <TableCell align="right">{cust.price}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow key={itemId}>
                          <TableCell>{itemName}</TableCell>
                          <TableCell align="right">-</TableCell>
                          <TableCell align="right">-</TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
        {/* Interactions Section */}
        <Box>
          <Typography variant="subtitle2" gutterBottom>
            Interactions
          </Typography>
          <Divider sx={{ my: 1 }} />
          {session.session_data.context && session.session_data.context.length > 0 ? (
            session.session_data.context.map((interaction, idx) => (
              <Card key={idx} sx={{ mb: 1, p: 1 }}>
                <CardContent>
                  <Typography variant="caption">
                    {interaction.role} | {interaction.type}
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 0.5 }}>
                    {interaction.text}
                  </Typography>
                  {interaction.data &&
                    Array.isArray(interaction.data) &&
                    interaction.data.length > 0 && (
                      <Box
                        sx={{
                          mt: 1,
                          display: "flex",
                          gap: 1,
                          flexWrap: "wrap",
                        }}
                      >
                        {interaction.data.map((d, i) => {
                          let name = "";
                          let image = "";
                          let price = "";
                          if (typeof d === "string") {
                            name = menuItems[d] || d;
                          } else if (typeof d === "object") {
                            name = d.name || menuItems[d.id] || d.id;
                            image = d.image || "";
                            price = d.price ? `₹${d.price}` : "";
                          }
                          return (
                            <Card key={i} sx={{ width: 200 }}>
                              {image && (
                                <CardMedia
                                  component="img"
                                  height="140"
                                  image={image}
                                  alt={name}
                                />
                              )}
                              <CardContent>
                                <Typography variant="body2">{name}</Typography>
                                {price && (
                                  <Typography variant="caption">
                                    {price}
                                  </Typography>
                                )}
                              </CardContent>
                            </Card>
                          );
                        })}
                      </Box>
                    )}
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography variant="body2">
              No interactions available.
            </Typography>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

/* ------------------ Outer Order Accordion ------------------ */
const OrderAccordion = ({ group, orderType, menuItems }) => {
  // Read order details from the completed order.
  const orderDetails = group.order_details || {};
  const orderId = orderDetails.id || "N/A";
  const tableId = orderDetails.table_id || "N/A";
  const orderTotal = orderDetails.total || "N/A";
  // Format created and completed times in IST with AM/PM.
  const createdAt = orderDetails.created_at ? formatDateTime(orderDetails.created_at) : "N/A";
  const completedAt = orderDetails.completed_at ? formatDateTime(orderDetails.completed_at) : "N/A";
  const sessionsCount = group.sessions ? group.sessions.length : 0;

  // Extract ordered items from orderDetails.json_data.items if available.
  let orderedItems = [];
  if (orderDetails.json_data && orderDetails.json_data.items) {
    const items = orderDetails.json_data.items;
    orderedItems = Object.entries(items).map(([itemId, item]) => {
      const itemName = item.name || menuItems[itemId] || itemId;
      return {
        itemId,
        itemName,
        qty: item.customizations && item.customizations.length > 0 ? item.customizations[0].qty : "-",
        price: item.customizations && item.customizations.length > 0 ? item.customizations[0].price : "-"
      };
    });
  }

  const [expandedSessionId, setExpandedSessionId] = useState(null);
  const handleSessionChange = (sessionId) => (event, isExpanded) => {
    setExpandedSessionId(isExpanded ? sessionId : null);
  };

  return (
    <Accordion sx={{ mb: 2 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box>
          <Typography variant="subtitle1">Table: {tableId}</Typography>
          <Typography variant="subtitle1">Order ID: {orderId}</Typography>
          <Typography variant="subtitle1">Total: ₹{orderTotal}</Typography>
          <Typography variant="subtitle1">Sessions: {sessionsCount}</Typography>
          <Typography variant="subtitle1">Created At: {createdAt}</Typography>
          <Typography variant="subtitle1">Completed At: {completedAt}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {/* Ordered Items Table */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" gutterBottom sx={{ backgroundColor: "#f5f5f5", p: 1 }}>
            Ordered Items (Total: ₹{orderTotal})
          </Typography>
          {orderedItems.length > 0 ? (
            <TableContainer component={Paper} variant="outlined">
              <Table size="small">
                <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
                  <TableRow>
                    <TableCell>Item Name</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Price (₹)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderedItems.map((item, idx) => (
                    <TableRow key={idx}>
                      <TableCell>{item.itemName}</TableCell>
                      <TableCell align="right">{item.qty}</TableCell>
                      <TableCell align="right">{item.price}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow sx={{ backgroundColor: "#e0e0e0" }}>
                    <TableCell colSpan={2} align="right">Overall Total</TableCell>
                    <TableCell align="right">₹{orderTotal}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body2">No ordered items available.</Typography>
          )}
        </Box>
        {/* Sessions Accordion */}
        {group.sessions && group.sessions.length > 0 ? (
          group.sessions.map((session) => (
            <SessionAccordion
              key={session.id}
              session={session}
              expanded={expandedSessionId === session.id}
              onChange={handleSessionChange(session.id)}
              menuItems={menuItems}
            />
          ))
        ) : (
          <Typography variant="body2">No sessions available for this order.</Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

/* ------------------ Main Analytics Component ------------------ */
const Analytics = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState("");
  const [butlerOrderGroups, setButlerOrderGroups] = useState([]);
  const [captainOrderGroups, setCaptainOrderGroups] = useState([]);
  const [otherOrderGroups, setOtherOrderGroups] = useState([]);
  const [totalButlerOrderValue, setTotalButlerOrderValue] = useState(0);
  const [totalCaptainOrderValue, setTotalCaptainOrderValue] = useState(0);
  const [totalOtherOrderValue, setTotalOtherOrderValue] = useState(0);
  const [menuItemNames, setMenuItemNames] = useState({});
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [dateError, setDateError] = useState("");
  const [loading, setLoading] = useState(false);

  // Unique order counts.
  const [butlerOrdersCount, setButlerOrdersCount] = useState(0);
  const [captainOrdersCount, setCaptainOrdersCount] = useState(0);
  const otherOrdersCount = otherOrderGroups.length;
  const totalOrdersCount = butlerOrdersCount + captainOrdersCount + otherOrdersCount;
  const overallOrderValue = totalButlerOrderValue + totalCaptainOrderValue + totalOtherOrderValue;

  // Fetch restaurants on mount.
  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const response = await axios.get("https://backend-cheeron.au-ki.com/onboard/restaurants");
        setRestaurants(response.data);
        if (response.data.length > 0) {
          setSelectedRestaurant(response.data[0].id);
        }
      } catch (error) {
        console.error("Error fetching restaurants:", error);
      }
    };
    fetchRestaurants();
  }, []);

  // Validate date range.
  useEffect(() => {
    if (fromDate && toDate && fromDate > toDate) {
      setDateError("From Date should be prior to To Date.");
    } else {
      setDateError("");
    }
  }, [fromDate, toDate]);

  const formatLocalDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Fetch orders and aggregated data from backend.
  const fetchCompletedSessions = async () => {
    setLoading(true);
    try {
      const params = { restaurantId: selectedRestaurant };
      if (fromDate && toDate) {
        const fd = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate());
        const td = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate());
        params.fromDate = formatLocalDate(fd);
        params.toDate = formatLocalDate(td);
      }
      const response = await axios.get("https://backend-cheeron.au-ki.com/analytics/full-data", { params });
      // Backend returns data under aggregatedData.
      const aggregated = response.data.aggregatedData;
      setButlerOrderGroups(aggregated.butlerOrderGroups || []);
      setCaptainOrderGroups(aggregated.captainOrderGroups || []);
      setOtherOrderGroups(aggregated.otherOrders || []);
      setTotalButlerOrderValue(aggregated.totalButlerOrderValue || 0);
      setTotalCaptainOrderValue(aggregated.totalCaptainOrderValue || 0);
      setTotalOtherOrderValue(aggregated.totalOtherOrderValue || 0);
      setMenuItemNames(aggregated.menuItems || {});
      setButlerOrdersCount(aggregated.butlerOrdersCount || 0);
      setCaptainOrdersCount(aggregated.captainOrdersCount || 0);
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    }
    setLoading(false);
  };

  // Download Excel file.
  const downloadExcel = async () => {
    if (!selectedRestaurant || !fromDate || !toDate || dateError) return;
    try {
      const params = { restaurantId: selectedRestaurant, export: "true" };
      if (fromDate && toDate) {
        const fd = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate());
        const td = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate());
        params.fromDate = formatLocalDate(fd);
        params.toDate = formatLocalDate(td);
      }
      const response = await axios.get("https://backend-cheeron.au-ki.com/analytics/full-data", {
        params,
        responseType: "blob",
      });
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      let fileName = `analytics_${selectedRestaurant}`;
      if (params.fromDate && params.toDate) {
        fileName += `_${params.fromDate}_${params.toDate}`;
      }
      fileName += ".xlsx";
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* Filters */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Select Restaurant</InputLabel>
        <Select
          value={selectedRestaurant}
          onChange={(e) => setSelectedRestaurant(e.target.value)}
          sx={{ width: 400 }}
        >
          {restaurants.map((r) => (
            <MenuItem key={r.id} value={r.id}>
              {r.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box sx={{ display: "flex", gap: 2, mb: 2, flexWrap: "wrap", alignItems: "center" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="From Date"
            value={fromDate}
            onChange={(newDate) => setFromDate(newDate)}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            label="To Date"
            value={toDate}
            onChange={(newDate) => setToDate(newDate)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <Typography variant="caption" color="error">
          {dateError || "For a single day filter, select the same date in both fields."}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={fetchCompletedSessions}
          disabled={!selectedRestaurant || !fromDate || !toDate || !!dateError}
        >
          Fetch Data
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={downloadExcel}
          disabled={!selectedRestaurant || !fromDate || !toDate || !!dateError}
        >
          Download Excel
        </Button>
      </Box>

      {/* Summary Table for Totals */}
      <Box sx={{ mb: 4 }}>
        <TableContainer component={Paper} variant="outlined">
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#e0e0e0" }}>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Order Type</TableCell>
                <TableCell align="right" sx={{ fontWeight: "bold" }}>Count</TableCell>
                <TableCell align="right" sx={{ fontWeight: "bold" }}>Total Value (₹)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Butler Orders</TableCell>
                <TableCell align="right">{butlerOrdersCount}</TableCell>
                <TableCell align="right">{totalButlerOrderValue.toFixed(2)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Captain Orders</TableCell>
                <TableCell align="right">{captainOrdersCount}</TableCell>
                <TableCell align="right">{totalCaptainOrderValue.toFixed(2)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Other Orders</TableCell>
                <TableCell align="right">{otherOrdersCount}</TableCell>
                <TableCell align="right">{totalOtherOrderValue.toFixed(2)}</TableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                <TableCell sx={{ fontWeight: "bold" }}>Overall</TableCell>
                <TableCell align="right" sx={{ fontWeight: "bold" }}>{totalOrdersCount}</TableCell>
                <TableCell align="right" sx={{ fontWeight: "bold" }}>{overallOrderValue.toFixed(2)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Order Accordions */}
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Butler Orders
            </Typography>
            {butlerOrderGroups && butlerOrderGroups.length > 0 ? (
              butlerOrderGroups.map((group, idx) => (
                <OrderAccordion
                  key={idx}
                  group={group}
                  orderType="butler"
                  menuItems={menuItemNames}
                />
              ))
            ) : (
              <Typography>No Butler Orders found.</Typography>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Captain Orders
            </Typography>
            {captainOrderGroups && captainOrderGroups.length > 0 ? (
              captainOrderGroups.map((group, idx) => (
                <OrderAccordion
                  key={idx}
                  group={group}
                  orderType="captain"
                  menuItems={menuItemNames}
                />
              ))
            ) : (
              <Typography>No Captain Orders found.</Typography>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Other Orders
            </Typography>
            {otherOrderGroups && otherOrderGroups.length > 0 ? (
              otherOrderGroups.map((group, idx) => (
                <OrderAccordion
                  key={idx}
                  group={group}
                  orderType="other"
                  menuItems={menuItemNames}
                />
              ))
            ) : (
              <Typography>No Other Orders found.</Typography>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Analytics;
