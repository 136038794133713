import React, { useState, useEffect } from "react";
import { Box, FormControl, InputLabel, Select, MenuItem, Tabs, Tab, CircularProgress, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import axios from "axios";
import FeedbackTable from "./FeedbackTable";

const Feedback = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState("");
  const [selectedRestaurantName, setSelectedRestaurantName] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [feedbackData, setFeedbackData] = useState([]);
  const [menuItems, setMenuItems] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null); // Store selected date

  const tabNames = [
    { label: "Dining Experience", tableName: "dining_experience" },
    { label: "Follow-up Response", tableName: "followup_response" },
    { label: "User Feedback", tableName: "user_feedback" },
    { label: "User Profiles", tableName: "user_profiles" },
    { label: "Between Dining Feedback", tableName: "between_dining_feedback" }
  ];

  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const response = await axios.get("https://backend-cheeron.au-ki.com/onboard/restaurants");
        setRestaurants(response.data);
        if (response.data.length > 0) {
          setSelectedRestaurant(response.data[0].id);
          setSelectedRestaurantName(response.data[0].title);
        }
      } catch (error) {
        console.error("Error fetching restaurants:", error);
      }
    };
    fetchRestaurants();
  }, []);

  useEffect(() => {
    if (selectedRestaurant) {
      fetchFeedbackData();
      fetchMenuItems();
    }
  }, [selectedRestaurant, activeTab]);

  const fetchFeedbackData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("https://backend-cheeron.au-ki.com/feedback/getFeedBack", {
        params: { restaurantId: selectedRestaurant, tableName: tabNames[activeTab].tableName },
      });

      let feedbackEntries = response.data || [];
      
      // Process follow-up responses: replace menu item IDs with actual names
      if (tabNames[activeTab].tableName === "followup_response") {
        feedbackEntries = feedbackEntries.map((entry) => {
          try {
            const parsedValue = JSON.parse(entry.value);
            if (Array.isArray(parsedValue)) {
              entry.value = parsedValue.map((id) => menuItems[id]?.name || id).join(", ");
            }
          } catch (error) {
            console.warn("Error parsing follow-up response value:", error);
          }
          return entry;
        });
      }

      setFeedbackData(feedbackEntries);
    } catch (error) {
      console.error("Error fetching feedback data:", error);
    }
    setIsLoading(false);
  };

  const fetchMenuItems = async () => {
    try {
      const response = await axios.get(`https://backend-cheeron.au-ki.com/restaurant-details/${selectedRestaurant}`);
      const menuData = response.data.menuItems || {};
      setMenuItems(menuData);
    } catch (error) {
      console.error("Error fetching menu items:", error);
    }
  };

  // Function to filter data by date
  const filterDataByDate = (data) => {
    if (!selectedDate) return data; // If no date selected, return all data
    const formattedSelectedDate = format(selectedDate, "yyyy-MM-dd");
    return data.filter((row) => format(new Date(row.created_at), "yyyy-MM-dd") === formattedSelectedDate);
  };

  return (
    <Box sx={{ padding: 3, maxWidth: "100%", margin: "auto" }}>
      <FormControl sx={{ marginBottom: "10px", width: "30%", margin: "auto" }}>
        <InputLabel>Select Restaurant</InputLabel>
        <Select
          value={selectedRestaurant}
          onChange={(e) => {
            const selectedOption = restaurants.find((r) => r.id === e.target.value);
            setSelectedRestaurant(e.target.value);
            setSelectedRestaurantName(selectedOption?.title || "Unknown");
          }}
        >
          {restaurants.map((restaurant) => (
            <MenuItem key={restaurant.id} value={restaurant.id}>
              {restaurant.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Select Date"
          value={selectedDate}
          onChange={(newDate) => setSelectedDate(newDate)}
          renderInput={(params) => <TextField {...params} fullWidth sx={{ marginBottom: 2 }} />}
        />
      </LocalizationProvider>

      <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)} centered>
        {tabNames.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </Tabs>

      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <FeedbackTable data={filterDataByDate(feedbackData)} restaurantName={selectedRestaurantName} />
      )}
    </Box>
  );
};

export default Feedback;
