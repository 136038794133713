import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Tabs,
  Tab,
  Checkbox,
  FormControlLabel,
  ListItem,
  ListItemText,
  Collapse,
  IconButton,
  Switch,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

const TablesAndAreas = () => {
  // Global states
  const [restaurants, setRestaurants] = useState([]);
  const [restaurantId, setRestaurantId] = useState('');
  // Each table: { id, table_number, area_id, area_name, active }
  const [tables, setTables] = useState([]);
  // Each area: { id, name, tables, active, sort_order }
  const [areas, setAreas] = useState([]);
  const [activeTab, setActiveTab] = useState(0); // 0: Tables, 1: Areas

  // New Tables creation state
  const [newTableInput, setNewTableInput] = useState('');

  // New Area creation state
  const [newAreaName, setNewAreaName] = useState('');
  const [newAreaSortOrder, setNewAreaSortOrder] = useState('');
  const [newAreaSelectedTables, setNewAreaSelectedTables] = useState([]);

  // Area editing state – keyed by area id: { name, sortOrder, selectedTables }
  const [expandedArea, setExpandedArea] = useState(null);
  const [areaEditData, setAreaEditData] = useState({});

  // --------------------
  // Fetch Functions
  // --------------------
  const fetchRestaurants = async () => {
    try {
      const res = await axios.get('https://backend-cheeron.au-ki.com/onboard/restaurants');
      setRestaurants(res.data);
      if (res.data.length > 0) {
        setRestaurantId(res.data[0].id);
      }
    } catch (error) {
      console.error('Error fetching restaurants:', error);
    }
  };

  const fetchTables = async () => {
    if (!restaurantId) return;
    try {
      // Backend returns only active tables.
      const res = await axios.get('https://backend-cheeron.au-ki.com/onboard/tables-areas/get-tables', {
        params: { restaurantId },
      });
      setTables(res.data || []);
    } catch (error) {
      console.error('Error fetching tables:', error);
    }
  };

  const fetchAreas = async () => {
    if (!restaurantId) return;
    try {
      // Backend returns only active areas.
      const res = await axios.get('https://backend-cheeron.au-ki.com/onboard/tables-areas/get-areas', {
        params: { restaurantId },
      });
      setAreas(res.data || []);
    } catch (error) {
      console.error('Error fetching areas:', error);
    }
  };

  useEffect(() => {
    fetchRestaurants();
  }, []);

  useEffect(() => {
    if (restaurantId) {
      fetchTables();
      fetchAreas();
    }
  }, [restaurantId]);

  // --------------------
  // TABLES TAB FUNCTIONS
  // --------------------
  const handleSubmitNewTables = async () => {
    if (!restaurantId || !newTableInput) {
      alert('Table input is required.');
      return;
    }
    try {
      const payload = { tableInput: newTableInput, restaurantId };
      await axios.post('https://backend-cheeron.au-ki.com/onboard/tables-areas/add-tables', payload);
      alert('Tables created successfully!');
      setNewTableInput('');
      fetchTables();
    } catch (error) {
      console.error('Error adding tables:', error);
      const errMsg = error.response?.data?.error || 'Failed to add tables.';
      alert(errMsg);
    }
  };

  const handleDeleteTable = async (tableId) => {
    if (!window.confirm('Are you sure you want to delete this table?')) return;
    try {
      await axios.delete('https://backend-cheeron.au-ki.com/onboard/tables-areas/delete-table', {
        data: { id: tableId, restaurantId },
      });
      alert('Table deleted successfully!');
      fetchTables();
    } catch (error) {
      console.error('Error deleting table:', error);
      const errMsg = error.response?.data?.error || 'Failed to delete table.';
      alert(errMsg);
    }
  };

  // Toggle active/inactive for a table.
  const handleToggleTableActive = async (table) => {
    try {
      await axios.put('https://backend-cheeron.au-ki.com/onboard/tables-areas/update-table-active', {
        id: table.id,
        restaurantId,
        active: !table.active,
      });
      alert(`Table ${table.table_number} is now ${!table.active ? 'active' : 'inactive'}.`);
      fetchTables();
    } catch (error) {
      console.error('Error toggling table active:', error);
      alert('Failed to update table status.');
    }
  };

  // --------------------
  // AREAS TAB FUNCTIONS
  // --------------------
  // Only list tables that are unassigned.
  const availableTablesForNewArea = tables.filter((t) => !t.area_id);

  const handleToggleNewAreaTable = (tableId) => {
    setNewAreaSelectedTables((prev) =>
      prev.includes(tableId) ? prev.filter((id) => id !== tableId) : [...prev, tableId]
    );
  };

  const handleSubmitNewArea = async () => {
    if (!restaurantId || !newAreaName) {
      alert('Restaurant and Area name are required.');
      return;
    }
    try {
      const payload = { 
        name: newAreaName, 
        tableIds: newAreaSelectedTables, 
        restaurantId,
        sortOrder: newAreaSortOrder ? Number(newAreaSortOrder) : null
      };
      await axios.post('https://backend-cheeron.au-ki.com/onboard/tables-areas/add-area', payload);
      alert('Area created successfully!');
      setNewAreaName('');
      setNewAreaSelectedTables([]);
      setNewAreaSortOrder('');
      fetchAreas();
      fetchTables();
    } catch (error) {
      console.error('Error adding area:', error);
      const errMsg = error.response?.data?.error || 'Failed to add area.';
      alert(errMsg);
    }
  };

  const handleDeleteArea = async (areaId) => {
    if (!window.confirm('Are you sure you want to delete this area?')) return;
    try {
      await axios.delete('https://backend-cheeron.au-ki.com/onboard/tables-areas/delete-area', {
        data: { id: areaId, restaurantId },
      });
      alert('Area deleted successfully!');
      fetchAreas();
      fetchTables();
    } catch (error) {
      console.error('Error deleting area:', error);
      const errMsg = error.response?.data?.error || 'Failed to delete area.';
      alert(errMsg);
    }
  };

  // Expand an area to edit its details (name, sort order, table assignments).
  const handleExpandArea = (areaId, area) => {
    if (expandedArea === areaId) {
      setExpandedArea(null);
    } else {
      setExpandedArea(areaId);
      if (!areaEditData[areaId]) {
        const assignedTables = tables.filter((t) => t.area_id === areaId).map((t) => t.id);
        setAreaEditData((prev) => ({
          ...prev, 
          [areaId]: { 
            name: area.name, 
            sortOrder: area.sort_order || '', 
            selectedTables: assignedTables 
          }
        }));
      }
    }
  };

  // Handle table selection changes in the area edit view.
  const handleAreaCheckboxChange = (areaId, tableId) => {
    const table = tables.find((t) => t.id === tableId);
    if (table && (table.area_id === areaId || !table.area_id)) {
      setAreaEditData((prev) => {
        const current = prev[areaId] || { name: '', sortOrder: '', selectedTables: [] };
        const alreadySelected = current.selectedTables.includes(tableId);
        const updated = alreadySelected
          ? current.selectedTables.filter((id) => id !== tableId)
          : [...current.selectedTables, tableId];
        return { ...prev, [areaId]: { ...current, selectedTables: updated } };
      });
    }
  };

  const handleToggleAreaActive = async (area) => {
    try {
      await axios.put('https://backend-cheeron.au-ki.com/onboard/tables-areas/update-area-active', {
        id: area.id,
        restaurantId,
        active: !area.active,
      });
      alert(`Area ${area.name} is now ${!area.active ? 'active' : 'inactive'}.`);
      fetchAreas();
      fetchTables();
    } catch (error) {
      console.error('Error toggling area active:', error);
      alert('Failed to update area status.');
    }
  };

  const handleUpdateArea = async (area) => {
    const data = areaEditData[area.id];
    if (!data || !data.name) {
      alert('Area name is required.');
      return;
    }
    try {
      const payload = { 
        id: area.id, 
        name: data.name, 
        tableIds: data.selectedTables, 
        restaurantId,
        sortOrder: data.sortOrder ? Number(data.sortOrder) : null
      };
      await axios.put('https://backend-cheeron.au-ki.com/onboard/tables-areas/update-area', payload);
      alert('Area updated successfully!');
      fetchAreas();
      fetchTables();
    } catch (error) {
      console.error('Error updating area:', error);
      const errMsg = error.response?.data?.error || 'Failed to update area.';
      alert(errMsg);
    }
  };

  return (
    <Container maxWidth="md">
      {/* Restaurant Selection */}
      <Box sx={{ mt: 3, mb: 3 }}>
        <FormControl fullWidth>
          <InputLabel>Restaurant</InputLabel>
          <Select
            value={restaurantId}
            onChange={(e) => setRestaurantId(e.target.value)}
            fullWidth
          >
            {restaurants.map((r) => (
              <MenuItem key={r.id} value={r.id}>
                {r.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Tabs */}
      <Tabs
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
        centered
      >
        <Tab label="Tables" />
        <Tab label="Areas" />
      </Tabs>

      {/* ---------------------- TABLES TAB ---------------------- */}
      {activeTab === 0 && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Add New Tables
          </Typography>
          <TextField
            label="Table No *"
            fullWidth
            helperText="Enter multiple table numbers separated by commas or ranges using colon (e.g., A10:A20, B1, C1:C5)"
            value={newTableInput}
            onChange={(e) => setNewTableInput(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Button variant="contained" onClick={handleSubmitNewTables}>
            Create Tables
          </Button>

          <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
            Existing Tables
          </Typography>
          {tables.length > 0 ? (
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  fontWeight: 'bold',
                  borderBottom: '2px solid #000',
                  py: 1,
                  pl: 1,
                }}
              >
                <Box sx={{ flex: 1 }}>Table No</Box>
                <Box sx={{ flex: 1 }}>Area</Box>
                <Box sx={{ width: '150px' }}>Actions</Box>
              </Box>
              {tables.map((table, index) => (
                <Box
                  key={table.id}
                  sx={{
                    display: 'flex',
                    py: 1,
                    borderBottom: '1px solid #ddd',
                    pl: 1,
                    backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff',
                  }}
                >
                  <Box sx={{ flex: 1 }}>{table.table_number}</Box>
                  <Box sx={{ flex: 1 }}>{table.area_name}</Box>
                  <Box sx={{ width: '150px', display: 'flex', alignItems: 'center' }}>
                    <Switch
                      checked={table.active}
                      onChange={() => handleToggleTableActive(table)}
                      color="primary"
                    />
                    <IconButton
                      color="error"
                      size="small"
                      onClick={() => handleDeleteTable(table.id)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </Box>
          ) : (
            <Typography variant="body2">No table found.</Typography>
          )}
        </Box>
      )}

      {/* ---------------------- AREAS TAB ---------------------- */}
      {activeTab === 1 && (
        <Box sx={{ mt: 3 }}>
          {/* Create New Area Section */}
          <Typography variant="h6" sx={{ mb: 2 }}>
            Create New Area
          </Typography>
          <TextField
            label="Area Name"
            fullWidth
            value={newAreaName}
            onChange={(e) => setNewAreaName(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Sort Order"
            type="number"
            fullWidth
            value={newAreaSortOrder}
            onChange={(e) => setNewAreaSortOrder(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Select Available Tables:
          </Typography>
          {availableTablesForNewArea.length > 0 ? (
            availableTablesForNewArea.map((table) => (
              <FormControlLabel
                key={table.id}
                control={
                  <Checkbox
                    checked={newAreaSelectedTables.includes(table.id)}
                    onChange={() => handleToggleNewAreaTable(table.id)}
                  />
                }
                label={table.table_number}
              />
            ))
          ) : (
            <Typography variant="body2">No available tables for assignment.</Typography>
          )}
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" onClick={handleSubmitNewArea}>
              Create Area
            </Button>
          </Box>

          {/* Existing Areas List */}
          <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
            Existing Areas
          </Typography>
          {areas.length > 0 ? (
            <Box>
              {areas.map((area) => (
                <Box key={area.id} sx={{ border: '1px solid #ddd', borderRadius: 1, mb: 2 }}>
                  <ListItem
                    button
                    onClick={() => handleExpandArea(area.id, area)}
                    sx={{
                      px: 2,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <ListItemText primary={`${area.name}: ${area.tables}`} />
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Switch
                        checked={area.active}
                        onChange={() => handleToggleAreaActive(area)}
                        color="primary"
                      />
                      <IconButton
                        color="error"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteArea(area.id);
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </ListItem>
                  <Collapse in={expandedArea === area.id} timeout="auto" unmountOnExit>
                    <Box sx={{ p: 2, backgroundColor: '#f0f0f0' }}>
                      <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        Edit Area:
                      </Typography>
                      <TextField
                        label="Area Name"
                        fullWidth
                        value={areaEditData[area.id]?.name || ''}
                        onChange={(e) =>
                          setAreaEditData((prev) => ({
                            ...prev,
                            [area.id]: { ...prev[area.id], name: e.target.value }
                          }))
                        }
                        sx={{ mb: 2 }}
                      />
                      <TextField
                        label="Sort Order"
                        type="number"
                        fullWidth
                        value={areaEditData[area.id]?.sortOrder || ''}
                        onChange={(e) =>
                          setAreaEditData((prev) => ({
                            ...prev,
                            [area.id]: { ...prev[area.id], sortOrder: e.target.value }
                          }))
                        }
                        sx={{ mb: 2 }}
                      />
                      {tables.map((table) => {
                        const modifiable = table.area_id === area.id || !table.area_id;
                        const isChecked = areaEditData[area.id]
                          ? areaEditData[area.id].selectedTables.includes(table.id)
                          : false;
                        return (
                          <FormControlLabel
                            key={table.id}
                            control={
                              <Checkbox
                                checked={isChecked}
                                onChange={() => modifiable && handleAreaCheckboxChange(area.id, table.id)}
                                disabled={!modifiable}
                              />
                            }
                            label={table.table_number}
                          />
                        );
                      })}
                      <Box sx={{ mt: 2 }}>
                        <Button variant="contained" onClick={() => handleUpdateArea(area)}>
                          Update Area
                        </Button>
                      </Box>
                    </Box>
                  </Collapse>
                </Box>
              ))}
            </Box>
          ) : (
            <Typography variant="body2">No areas found.</Typography>
          )}
        </Box>
      )}
    </Container>
  );
};

export default TablesAndAreas;
