import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  TextField,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  FormGroup,
  FormControlLabel,
  Tabs,
  Tab,
  Collapse,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

const AddAddons = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [restaurantId, setRestaurantId] = useState('');
  const [addons, setAddons] = useState([]);
  const [expandedAddon, setExpandedAddon] = useState(null);
  const [name, setName] = useState('');
  const [active, setActive] = useState(true);
  const [minSelection, setMinSelection] = useState(0);
  const [maxSelection, setMaxSelection] = useState(1);
  const [options, setOptions] = useState([{ name: '', price: 0, isEnabled: true }]);
  const [activeTab, setActiveTab] = useState(0);

  const fetchRestaurants = async () => {
    try {
      const response = await axios.get('https://backend-cheeron.au-ki.com/onboard/restaurants');
      setRestaurants(response.data);
      if (response.data.length > 0) {
        setRestaurantId(response.data[0].id);
      }
    } catch (error) {
      console.error('Error fetching restaurants:', error);
    }
  };

  const fetchAddons = async () => {
    if (!restaurantId) return;

    try {
      const response = await axios.get('https://backend-cheeron.au-ki.com/onboard/get-addons', {
        params: { restaurantId },
      });
      setAddons(response.data || []);
    } catch (error) {
      console.error('Error fetching addons:', error);
    }
  };

  useEffect(() => {
    fetchRestaurants();
  }, []);

  useEffect(() => {
    if (restaurantId) {
      fetchAddons();
    }
  }, [restaurantId]);

  const handleExpandAddon = (id) => {
    setExpandedAddon(expandedAddon === id ? null : id);
  };

  const handleAddOption = () => {
    setOptions([...options, { name: '', price: 0, isEnabled: true }]);
  };


  const handleFieldChange = (id, field, value) => {
    setAddons(addons.map((addon) =>
      addon.id === id ? { ...addon, [field]: value } : addon
    ));
  };

  const handleOptionChange = (addonId, index, field, value) => {
    setAddons(addons.map((addon) => {
      if (addon.id === addonId) {
        const updatedOptions = [...addon.options];
        updatedOptions[index][field] = value;
        return { ...addon, options: updatedOptions };
      }
      return addon;
    }));
  };

  const handleUpdateAddon = async (addon) => {
    try {
    const updatedAddon = { ...addon, restaurantid: restaurantId }; 
      await axios.put('https://backend-cheeron.au-ki.com/onboard/update-addons', updatedAddon);
      alert('Addon updated successfully!');
      fetchAddons();
    } catch (error) {
      console.error('Error updating addon:', error);
      alert('Failed to update addon.');
    }
  };

  const handleRemoveOption = (index) => {
    setOptions(options.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    if (!restaurantId || !name) {
      alert('Restaurant and Name are required.');
      return;
    }

    if (options.some((option) => !option.name || option.price === undefined)) {
      alert('All options must have a name and price.');
      return;
    }

    const newAddon = {
      id: `addon-${uuidv4()}`,
      name,
      active,
      options,
      minselection: minSelection,
      maxselection: maxSelection,
      restaurantid: restaurantId,
    };

    try {
      await axios.post('https://backend-cheeron.au-ki.com/onboard/add-addons', newAddon);
      alert('Addon created successfully!');
      setName('');
      setActive(true);
      setMinSelection(0);
      setMaxSelection(1);
      setOptions([{ name: '', price: 0, isEnabled: true }]);
    } catch (error) {
      console.error('Error creating addon:', error);
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 5 }}>
        <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)} centered>
          <Tab label="Addons" />
          <Tab label="Add Addons" />
        </Tabs>

        {activeTab === 0 && (
          <Box>
            <FormControl fullWidth>
              <InputLabel>Restaurant</InputLabel>
              <Select
                value={restaurantId}
                onChange={(e) => setRestaurantId(e.target.value)}
                fullWidth
              >
                {restaurants.map((restaurant) => (
                  <MenuItem key={restaurant.id} value={restaurant.id}>
                    {restaurant.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <List>
              {addons.map((addon) => (
                <Box key={addon.id}>
                  <ListItem
                    button
                    onClick={() => handleExpandAddon(addon.id)}
                    sx={{ border: '1px solid #ddd', mb: 1 }}
                  >
                    <ListItemText primary={addon.name} secondary={`ID: ${addon.id}`} />
                  </ListItem>
                  <Collapse in={expandedAddon === addon.id} timeout="auto" unmountOnExit>
                    <Box sx={{ p: 2, border: '1px solid #ddd', borderRadius: 1 }}>
                      <TextField
                        label="Name"
                        fullWidth
                        value={addon.name}
                        onChange={(e) => handleFieldChange(addon.id, 'name', e.target.value)}
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={addon.active}
                            onChange={(e) => handleFieldChange(addon.id, 'active', e.target.checked)}
                          />
                        }
                        label="Active"
                      />
                      <TextField
                        label="Min Selection"
                        type="number"
                        fullWidth
                        value={addon.minselection}
                        onChange={(e) => handleFieldChange(addon.id, 'minselection', Number(e.target.value))}
                        style={{ marginTop: '10px' }}
                      />
                      <TextField
                        label="Max Selection"
                        type="number"
                        fullWidth
                        value={addon.maxselection}
                        onChange={(e) => handleFieldChange(addon.id, 'maxselection', Number(e.target.value))}
                        style={{ marginTop: '10px' }}

                      />
                      <Typography 
                        style={{ marginTop: '10px' }}
                      >Options:</Typography>
                      {addon.options.map((option, index) => (
                        <Box key={index} sx={{ display: 'flex', gap: 2, mb: 2 }}>
                          <TextField
                            label="Option Name"
                            value={option.name}
                            onChange={(e) =>
                              handleOptionChange(addon.id, index, 'name', e.target.value)
                            }
                            style={{ marginTop: '10px' }}

                          />
                          <TextField
                            label="Price"
                            type="number"
                            value={option.price}
                            onChange={(e) =>
                              handleOptionChange(addon.id, index, 'price', Number(e.target.value))
                            }
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                checked={option.isEnabled}
                                onChange={(e) =>
                                  handleOptionChange(addon.id, index, 'isEnabled', e.target.checked)
                                }
                              />
                            }
                            label="Enabled"
                          />
                        </Box>
                      ))}
                      <Button
                        variant="contained"
                        onClick={() => handleUpdateAddon(addon)}
                        sx={{ mt: 2 }}
                      >
                        Update
                      </Button>
                    </Box>
                  </Collapse>
                </Box>
              ))}
            </List>
          </Box>
        )}

        {activeTab === 1 && (
          <Box>
            <FormControl fullWidth>
              <InputLabel>Restaurant</InputLabel>
              <Select
                value={restaurantId}
                onChange={(e) => setRestaurantId(e.target.value)}
                fullWidth
              >
                {restaurants.map((restaurant) => (
                  <MenuItem key={restaurant.id} value={restaurant.id}>
                    {restaurant.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Addon Name"
              variant="outlined"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{ marginTop: '10px' }}

            />

            <FormControlLabel
              control={<Switch checked={active} onChange={(e) => setActive(e.target.checked)} />}
              label="Active"
              style={{ marginTop: '10px' }}

            />

            <TextField
              label="Minimum Selection"
              variant="outlined"
              type="number"
              value={minSelection}
              onChange={(e) => setMinSelection(Number(e.target.value))}
              style={{ marginTop: '10px' }}

            />

            <TextField
              label="Maximum Selection"
              variant="outlined"
              type="number"
              value={maxSelection}
              onChange={(e) => setMaxSelection(Number(e.target.value))}
              style={{ marginTop: '10px' }}

            />

            <Box>
              <Typography variant="h6">Options</Typography>
              {options.map((option, index) => (
                <Box key={index} sx={{ display: 'flex', gap: 2, mb: 2 }}>
                  <TextField
                    label="Option Name"
                    variant="outlined"
                    value={option.name}
                    onChange={(e) => handleOptionChange(index, 'name', e.target.value)}
                  />
                  <TextField
                    label="Price"
                    variant="outlined"
                    type="number"
                    value={option.price}
                    onChange={(e) => handleOptionChange(index, 'price', Number(e.target.value))}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={option.isEnabled}
                        onChange={(e) =>
                          handleOptionChange(index, 'isEnabled', e.target.checked)
                        }
                      />
                    }
                    label="Enabled"
                  />
                  <Button color="error" onClick={() => handleRemoveOption(index)}>
                    Remove
                  </Button>
                </Box>
              ))}
              <Button onClick={handleAddOption} variant="outlined">
                Add Option
              </Button>
            </Box>

            <Button variant="contained" 
                        style={{ marginTop: '10px' }}
            onClick={handleSubmit}>
                
              Submit Addon
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default AddAddons;
