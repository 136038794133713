import React, { useState, useEffect } from 'react';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem, Container, Modal, TextField, Snackbar, Alert } from '@mui/material';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';

const SubCategoryManagement = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [restaurantId, setRestaurantId] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [menuItemsMap, setMenuItemsMap] = useState({});
  const [allMenuItems, setAllMenuItems] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [removedItems, setRemovedItems] = useState(new Set()); // Stores removed item IDs
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [modalSearch, setModalSearch] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [openAlert, setOpenAlert] = useState(false);

  const showAlert = (message) => {
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const fetchRestaurants = async () => {
    try {
      const res = await axios.get('https://backend-cheeron.au-ki.com/onboard/restaurants');
      setRestaurants(res.data);
      if (res.data.length > 0) {
        setRestaurantId(res.data[0].id);
      }
    } catch {
      showAlert('Error fetching restaurants.');
    }
  };

  useEffect(() => {
    if (restaurantId) {
      fetchSubCategories();
    }
  }, [restaurantId]);

  const fetchSubCategories = async () => {
    try {
      if (!restaurantId) return;
      const res = await axios.get(`https://backend-cheeron.au-ki.com/onboard/subcategories?restaurantId=${restaurantId}`);
      setSubCategories(res.data);
      setFilteredSubCategories(res.data);
      fetchMenuItemsByIds(res.data.flatMap((c) => c.items));
    } catch {
      showAlert('Error fetching subcategories.');
    }
  };

  const fetchMenuItemsByIds = async (itemIds) => {
    if (!itemIds.length) return;
    try {
      const res = await axios.post('https://backend-cheeron.au-ki.com/onboard/items-by-ids', { itemIds });
      const itemMap = {};
      res.data.forEach((i) => {
        itemMap[i.id] = i.name;
      });
      setMenuItemsMap(itemMap);
    } catch {
      console.error('Error fetching menu items by IDs');
    }
  };

  const fetchAllMenuItems = async () => {
    try {
      const res = await axios.get(`https://backend-cheeron.au-ki.com/onboard/items-restaurant?restaurantId=${restaurantId}`);
      setAllMenuItems(res.data);
    } catch {
      showAlert('Error fetching all menu items.');
    }
  };

  const handleUpdateSubCategory = async (id, title, items, active, displayImage) => {
    const finalItems = items.filter((itemId) => !removedItems.has(itemId)); // Remove only selected items

    try {
      await axios.put('https://backend-cheeron.au-ki.com/onboard/update-subcategory', {
        id,
        title,
        items: finalItems, // Send only updated items list
        active,
        displayImage,
      });
      showAlert('Subcategory updated successfully.');
      fetchSubCategories();
      setRemovedItems(new Set()); // Reset removed items
    } catch {
      showAlert('Failed to update subcategory.');
    }
  };

  const handleRemoveItem = (itemId) => {
    setRemovedItems((prev) => new Set([...prev, itemId]));
    
    // ✅ Immediately filter items in the selected subcategory for UI update
    setSelectedSubCategory((prev) => ({
      ...prev,
      items: prev.items.filter((id) => id !== itemId),
    }));
  };
  

  const toggleSelectItem = (itemId) => {
    setSelectedItems((prev) =>
      prev.includes(itemId) ? prev.filter((id) => id !== itemId) : [...prev, itemId]
    );
  };

  useEffect(() => {
    fetchRestaurants();
  }, []);

  return (
    <Container maxWidth="70%">
      <FormControl sx={{ width: '40%' }}>
        <InputLabel>Restaurant</InputLabel>
        <Select value={restaurantId} onChange={(e) => setRestaurantId(e.target.value)} disabled={!restaurants.length}>
          {restaurants.map((r) => (
            <MenuItem key={r.id} value={r.id}>
              {r.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px', textAlign: 'left' }}>
        <thead>
          <tr>
            <th>Title</th>
            <th>Items</th>
            <th>Active</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredSubCategories.map((sc) => (
            <tr key={sc.id}>
              <td>{sc.title}</td>
              <td>
                <button onClick={() => setSelectedSubCategory(sc) || setIsViewModalOpen(true)}>Remove Items</button>
                <button onClick={() => setSelectedSubCategory(sc) || setIsAddModalOpen(true) || fetchAllMenuItems()}>
                  Add Items
                </button>
              </td>
              <td>
                <FormControl>
                  <Select
                    value={sc.active}
                    onChange={(e) => handleUpdateSubCategory(sc.id, sc.title, sc.items, e.target.value, sc.display_image)}
                  >
                    <MenuItem value={true}>Active</MenuItem>
                    <MenuItem value={false}>Inactive</MenuItem>
                  </Select>
                </FormControl>
              </td>
              <td>{sc.display_image ? <img src={sc.display_image} alt="Subcategory" width="50" /> : 'No Image'}</td>
              <td>
                <button
                  onClick={() =>
                    handleUpdateSubCategory(sc.id, sc.title, sc.items, sc.active, sc.display_image)
                  }
                >
                  Update
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Remove Items Modal */}
    <Modal open={isViewModalOpen} onClose={() => setIsViewModalOpen(false)}>
    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, maxHeight: '80%', overflowY: 'scroll', bgcolor: 'background.paper', p: 4 }}>
        <Typography variant="h6">{selectedSubCategory?.title} - Items</Typography>
        <TextField label="Search Items" variant="outlined" value={modalSearch} onChange={(e) => setModalSearch(e.target.value)} fullWidth />
        
        {/* ✅ Dynamically filter items that are not removed */}
        {selectedSubCategory?.items.filter(itemId => !removedItems.has(itemId)).map((itemId) => (
        <button key={itemId} onClick={() => handleRemoveItem(itemId)} style={{ margin: '5px', padding: '5px' }}>
            {menuItemsMap[itemId]} <CloseIcon fontSize="small" />
        </button>
        ))}
        
        <button onClick={() => setIsViewModalOpen(false)}>Close</button>
    </Box>
    </Modal>


      {/* Add Items Modal */}
      <Modal open={isAddModalOpen} onClose={() => setIsAddModalOpen(false)}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, maxHeight: '80%', overflowY: 'scroll', bgcolor: 'background.paper', p: 4 }}>
          <Typography variant="h6">Add Items to {selectedSubCategory?.title}</Typography>
          <TextField label="Search Items" variant="outlined" value={modalSearch} onChange={(e) => setModalSearch(e.target.value)} fullWidth />
          {allMenuItems.map((item) => (
            <button key={item.id} onClick={() => toggleSelectItem(item.id)} style={{ margin: '5px', padding: '5px' }}>
              {item.name}
            </button>
          ))}
          <button onClick={() => setIsAddModalOpen(false)}>Close</button>
        </Box>
      </Modal>

      <Snackbar open={openAlert} autoHideDuration={3000} onClose={() => setOpenAlert(false)}>
        <Alert onClose={() => setOpenAlert(false)} severity="info">{alertMessage}</Alert>
      </Snackbar>
    </Container>
  );
};

export default SubCategoryManagement;
