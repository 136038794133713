import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, Switch, TextField } from '@mui/material';
import axios from 'axios';

const UpdateRecommendations = ({ restaurantId }) => {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const [changes, setChanges] = useState({});
  const [allChecked, setAllChecked] = useState(true); // Track check all/uncheck all state

  useEffect(() => {
    if (restaurantId) {
      fetchMenuItems(restaurantId);
    }
  }, [restaurantId]);

  useEffect(() => {
    if (searchKeyword) {
      setFilteredItems(items.filter(item => item.name.toLowerCase().includes(searchKeyword.toLowerCase())));
    } else {
      setFilteredItems(items);
    }
  }, [searchKeyword, items]);

  const fetchMenuItems = async (restaurantId) => {
    setLoading(true);
    try {
      const response = await axios.get(`https://backend-cheeron.au-ki.com/onboard/items-restaurant?restaurantId=${restaurantId}`);
      const recommendedItems = response.data.filter(item => item.isrecommend);
      setItems(recommendedItems);
      setFilteredItems(recommendedItems.map(item => ({ ...item })));
    } catch (error) {
      console.error('Error fetching menu items:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (itemId, checked) => {
    setFilteredItems(filteredItems.map(item => item.id === itemId ? { ...item, isrecommend: checked } : item));

    setChanges(prev => ({
      ...prev,
      [itemId]: { ...prev[itemId], isrecommend: checked }
    }));
  };

  const handleRankChange = (itemId, value) => {
    setFilteredItems(filteredItems.map(item => item.id === itemId ? { ...item, rank: value } : item));

    setChanges(prev => ({
      ...prev,
      [itemId]: { ...prev[itemId], rank: value }
    }));
  };

  const handleCheckAll = () => {
    const newCheckedState = !allChecked; // Toggle state
    setAllChecked(newCheckedState);

    const updatedItems = filteredItems.map(item => ({
      ...item,
      isrecommend: newCheckedState
    }));

    setFilteredItems(updatedItems);

    const updatedChanges = {};
    updatedItems.forEach(item => {
      updatedChanges[item.id] = { isrecommend: newCheckedState };
    });

    setChanges(updatedChanges);
  };

  const handleUpdate = async () => {
    if (Object.keys(changes).length === 0) {
      alert('No changes detected.');
      return;
    }

    try {
      await axios.put('https://backend-cheeron.au-ki.com/recommend/update-recommend', { 
        restaurantId, 
        updates: changes 
      });
      alert('Updated successfully!');
      setChanges({});
    } catch (error) {
      console.error('Error updating recommendations:', error);
      alert('Failed to update recommendations.');
    }
  };

  return (
    <Box sx={{ mt: 3 }}>
      <TextField
        label="Search Menu Items"
        value={searchKeyword}
        onChange={(e) => setSearchKeyword(e.target.value)}
        sx={{ width: '100%', mt: 2 }}
        placeholder="Enter keyword to search"
      />

      {/* Check All / Uncheck All Button */}
      <Button 
        variant="contained" 
        color="secondary" 
        sx={{ width: '30%', mt: 2, mb: 2, mr: 2 }} 
        onClick={handleCheckAll}
      >
        {allChecked ? 'Uncheck All' : 'Check All'}
      </Button>

      {/* Update Button */}
      <Button 
        variant="contained" 
        color="primary" 
        sx={{ width: '30%', mt: 2, mb: 2 }} 
        onClick={handleUpdate}
      >
        Update Recommendations
      </Button>

      {loading ? (
        <CircularProgress />
      ) : (
        <Box sx={{ overflowY: 'auto', mt: 3, height: '65vh' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
            <thead>
              <tr>
                <th style={{ padding: '5px', border: '1px solid #ddd', textAlign: 'left' }}>Item Name</th>
                <th style={{ padding: '5px', border: '1px solid #ddd', textAlign: 'center' }}>Recommended</th>
                <th style={{ padding: '5px', border: '1px solid #ddd', textAlign: 'center' }}>Rank</th>
              </tr>
            </thead>
            <tbody>
              {filteredItems.map((item) => (
                <tr key={item.id}>
                  <td style={{ padding: '5px', border: '1px solid #ddd' }}>{item.name}</td>
                  <td style={{ padding: '5px', border: '1px solid #ddd', textAlign: 'center' }}>
                    <Switch
                      checked={item.isrecommend}
                      onChange={(e) => handleCheckboxChange(item.id, e.target.checked)}
                    />
                  </td>
                  <td style={{ padding: '5px', border: '1px solid #ddd', textAlign: 'center' }}>
                    <TextField
                      type="number"
                      value={item.rank}
                      onChange={(e) => handleRankChange(item.id, e.target.value)}
                      sx={{ width: '80px' }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      )}
    </Box>
  );
};

export default UpdateRecommendations;
