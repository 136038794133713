import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  IconButton,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Tab,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Switch,
  Modal
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { v4 as uuidv6 } from "uuid";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";


const meal_period = ['Breakfast', 'Brunch', 'Lunch', 'Afternoon', 'Dinner'];
const dietary_info = [
  'Vegetarian', 'Vegan', 'Gluten-Free', 'Dairy-Free', 'Nut-Free', 'Egg-Free',
  'Soy-Free', 'Halal', 'Kosher', 'Pescatarian', 'Low-Carb', 'Low-Fat',
  'Low-Sodium', 'Sugar-Free', 'Keto-Friendly', 'Paleo-Friendly',
];
const allergens = [
  'Peanuts', 'Tree Nuts', 'Dairy', 'Eggs', 'Soy', 'Wheat (Gluten)', 'Fish',
  'Shellfish', 'Sesame', 'Mustard', 'Sulfites', 'Lupin', 'Celery',
];
const taste = [
  'Sweet', 'Savory', 'Sour', 'Bitter', 'Salty', 'Umami', 'Spicy', 'Tangy',
  'Smoky', 'Herbal', 'Fruity', 'Creamy',
];
const preparation_method = [
  'Grilled', 'Fried', 'Baked', 'Roasted', 'Steamed', 'Boiled', 'Sautéed',
  'Stir-Fried', 'Raw', 'Smoked', 'Poached', 'Braised', 'Blanched', 'Marinated',
  'Slow-Cooked', 'Sous Vide',
];
const cuisines = [
  'Italian', 'Chinese', 'Mexican', 'Indian', 'North Indian', 'South Indian',
  'French', 'Japanese', 'Thai', 'Mediterranean', 'American', 'Greek', 'Spanish',
  'Vietnamese', 'Lebanese', 'Turkish', 'Korean', 'Brazilian', 'Ethiopian',
  'Moroccan', 'Caribbean', 'German', 'Portuguese', 'Other',
];
const serving_temp = ['Hot', 'Cold'];


const generateMenuItemId = () => `menuItem-${uuidv4().slice(0, 8)}`;
const generateVariationId = (name) =>
  `${name.toLowerCase().replace(/\s+/g, "-")}-${uuidv6().slice(0, 4)}`;

const AddMenuItems = () => {
  const [activeTab, setActiveTab] = useState(0); // Tabs: Grid (0), Excel (1)
  const [menuItems, setMenuItems] = useState([
    {
      id: generateMenuItemId(),
      name: '',
      price: '',
      description: '',
      isVeg: false,
      image: '',
      active: false,
      isRecommend: false,
      variations: '',
      ordertype: '',
      preparationtime: '',
      discount_type: '',
      discount_value: '',
      tagsOriginal: {
        meal_period: [],
        dietary_info: [],
        allergens: [],
        taste: [],
        preparation_method: [],
        cuisines: [],
        calories: null,
        serving_size: null,
        contains_alcohol: null,
        spice_level: null,
        ingredients: [],
        preparation_Time: null,
        serving_temp: []
      },
      tags: {}
    },
  ]);
  

  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [tagModalIndex, setTagModalIndex] = useState(null);
  const [tagData, setTagData] = useState({
    meal_period: [],
    dietary_info: [],
    allergens: [],
    taste: [],
    preparation_method: [],
    cuisines: [],
    calories: null,
    serving_size: null,
    contains_alcohol: null,
    spice_level: null,
    ingredients: [],
    preparation_Time: null,
    serving_temp: []
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState("");
  const [categories, setCategories] = useState([]);
  const [errors, setErrors] = useState({});
  const [isExcelUploading, setIsExcelUploading] = useState(false);
  const [swiggyId, setSwiggyId] = useState("");

  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const response = await axios.get(
          "https://backend-cheeron.au-ki.com/onboard/restaurants"
        );
        setRestaurants(response.data);
        if (response.data.length > 0)
          setSelectedRestaurant(response.data[0].id); // Select first restaurant by default
      } catch (error) {
        showAlert("Error fetching restaurants");
      }
    };
    fetchRestaurants();
  }, []);

  useEffect(() => {
    if (selectedRestaurant) {
      const fetchCategories = async () => {
        try {
          const response = await axios.get(
            `https://backend-cheeron.au-ki.com/onboard/categories?restaurantId=${selectedRestaurant}`
          );
          setCategories(response.data);
        } catch (error) {
          showAlert("Error fetching categories");
        }
      };
      fetchCategories();
    }
  }, [selectedRestaurant]);

  const showAlert = (message) => {
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const handleTagChange = (field, value) => {
    setTagData((prev) => ({ ...prev, [field]: value }));
  };

  const saveTagData = () => {
    const updatedItems = [...menuItems];
    updatedItems[tagModalIndex].tagsOriginal = { ...tagData };
    setMenuItems(updatedItems);
    setTagModalOpen(false);
  };

  const openTagModal = (index) => {
    setTagModalIndex(index);
    setTagModalOpen(true);
    setTagData(
      menuItems[index].tagsOriginal || {
        meal_period: [],
        dietary_info: [],
        allergens: [],
        taste: [],
        preparation_method: [],
        cuisines: [],
        calories: null,
        serving_size: null,
        contains_alcohol: null,
        spice_level: null,
        ingredients: [],
        preparation_Time: null,
        serving_temp: []
      }
    );
  };
  

  const validateAllFields = () => {
    let hasErrors = false;
    const updatedErrors = { ...errors };
    menuItems.forEach((item, index) => {
      ["name", "price"].forEach((field) => {
        if (!item[field]) {
          updatedErrors[`${index}-${field}`] = `${field} is required`;
          hasErrors = true;
        }
      });
    });
    setErrors(updatedErrors);
    return !hasErrors;
  };

  const saveMenuItems = async () => {
    if (!validateAllFields()) {
      showAlert("Please fill in all required fields");
      return;
    }
    const itemsWithRestaurant = menuItems.map((item) => ({
      ...item,
      restaurantId: selectedRestaurant,
    }));

    try {
      await axios.post(
        "https://backend-cheeron.au-ki.com/update/add-menu-item",
        itemsWithRestaurant
      );
      showAlert("Menu items added successfully!");
      setMenuItems([
        {
          id: generateMenuItemId(),
          name: "",
          price: "",
          description: "",
          isVeg: false,
          image: "",
          active: false,
          isRecommend: false,
          variations: "",
          ordertype: "",
          preparationtime: "",
          discount_type: "",
          discount_value: "",
          tags: "",
          tagsOriginal:""
        },
      ]);
      setErrors({});
    } catch (error) {
      showAlert("Failed to add menu items.");
    }
  };

  const handleDownloadExcel = async () => {
    try {
      const restaurantName =
        restaurants
          .find((r) => r.id === selectedRestaurant)
          ?.title.replace(/[^a-zA-Z0-9]/g, "_") || "restaurant";
      const filename = `${restaurantName}-menu-add.xlsx`;
      const url = `https://backend-cheeron.au-ki.com/update/download-menu-template?restaurantId=${selectedRestaurant}`;

      const response = await axios.get(url, { responseType: "blob" });
      const blob = new Blob([response.data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      showAlert("Excel template downloaded successfully!");
    } catch (error) {
      console.error("Error downloading Excel:", error);
      showAlert("Failed to download Excel template.");
    }
  };

  const handleExcelUpload = async (e) => {
    const file = e.target.files[0];
    console.log(selectedRestaurant);
    if (!file) {
      showAlert("Please select a file.");
      return;
    }

    if (!selectedRestaurant) {
      showAlert("Please select a restaurant from the dropdown.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("restaurantId", selectedRestaurant);

    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      setIsExcelUploading(true);
      await axios.post(
        "https://backend-cheeron.au-ki.com/update/upload-menu-excel",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      showAlert("Excel file uploaded and menu items added successfully!");
    } catch (error) {
      console.error("Error uploading Excel:", error);
      showAlert("Failed to upload and add menu items from Excel.");
    } finally {
      setIsExcelUploading(false);
    }
  };

  const handleFieldChange = (index, field, value) => {
    const updatedItems = [...menuItems];
    updatedItems[index][field] = value;
    setMenuItems(updatedItems);

    validateField(index, field, value);
  };

  const handleNestedFieldChange = (menuItemIndex, variationIndex, field, value) => {
    const updatedItems = [...menuItems];
    updatedItems[menuItemIndex].variations[variationIndex][field] = value;
    setMenuItems(updatedItems);
};


  const addNewRow = () => {
    setMenuItems([
      ...menuItems,
      {
        id: generateMenuItemId(),
        name: "",
        price: "",
        description: "",
        isVeg: false,
        image: "",
        active: false,
        isRecommend: false,
        variations: "",
        ordertype: "",
        preparationtime: "",
        discount_type: "",
        discount_value: "",
        tags: {},
      },
    ]);
  };

  const removeRow = (index) => {
    const updatedItems = [...menuItems];
    updatedItems.splice(index, 1);
    setMenuItems(updatedItems);
  };

  const validateField = (index, field, value) => {
    const updatedErrors = { ...errors };
    if (
      (field === "name" || field === "price" || field === "categoryId") &&
      !value
    ) {
      updatedErrors[`${index}-${field}`] = `${field} is required`;
    } else {
      delete updatedErrors[`${index}-${field}`];
    }
    setErrors(updatedErrors);
  };

  const handleImageUpload = async (index, file) => {

    if (!file) {
      showAlert("Please select a valid image file.");
      return;
    }
    const formData = new FormData();
    formData.append("image", file);
    try {
      const response = await axios.post(
        "https://backend-cheeron.au-ki.com/update/upload-image",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          params: { folderName: "menu_items" },
        }
      );
      const updatedItems = [...menuItems];
      updatedItems[index].image = response.data.imageUrl;
      setMenuItems(updatedItems);
      showAlert("Image uploaded successfully!");
    } catch (error) {
      showAlert("Failed to upload image.");
    }
  };

  const handleOrderTypeChange = (index, option) => {
    const updatedItems = [...menuItems];
    const ordertype = updatedItems[index].ordertype;

    if (ordertype.includes(option)) {
      updatedItems[index].ordertype = ordertype.filter(
        (type) => type !== option
      );
    } else {
      updatedItems[index].ordertype = [...ordertype, option];
    }

    setMenuItems(updatedItems);
  };

  const handleVariationToggle = (index) => {
    const updatedItems = [...menuItems];
    if (!updatedItems[index].variations || updatedItems[index].variations.length === 0) {
        updatedItems[index].variations = [
            {
                id: generateVariationId("variation"),
                name: "",
                price: "",
                default: false,
                isEnabled: true
            }
        ];
    } else {
        updatedItems[index].variations = [];
    }
    setMenuItems(updatedItems);
};


  const handleOptionChange = (
    menuItemIndex,
    variationIndex,
    optionField,
    value,
    optionIndex
  ) => {
    const updatedItems = [...menuItems];
    updatedItems[menuItemIndex].variations[variationIndex].options[optionIndex][
      optionField
    ] = value;
    setMenuItems(updatedItems);
  };

  const addNewOption = (menuItemIndex, variationIndex) => {
    const updatedItems = [...menuItems];
    updatedItems[menuItemIndex].variations[variationIndex].options.push({
      id: generateVariationId("option"),
      name: "",
      rank: "",
      price: "",
      active: true,
      default: false,
    });
    setMenuItems(updatedItems);
  };

  const removeOption = (menuItemIndex, variationIndex, optionIndex) => {
    const updatedItems = [...menuItems];
    updatedItems[menuItemIndex].variations[variationIndex].options.splice(
      optionIndex,
      1
    );
    setMenuItems(updatedItems);
  };

  useEffect(() => {
    if (selectedRestaurant) {
      const fetchCategories = async () => {
        try {
          const response = await axios.get(
            `https://backend-cheeron.au-ki.com/onboard/categories?restaurantId=${selectedRestaurant}`
          );
          setCategories(response.data);
        } catch (error) {
          showAlert("Error fetching categories");
        }
      };
      fetchCategories();
    }
  }, [selectedRestaurant]);

  const handleSwiggyImport = async () => {
    if (!swiggyId || !selectedRestaurant) {
      showAlert("Please enter Swiggy ID and select a restaurant.");
      return;
    }
  
    const url = `https://backend-cheeron.au-ki.com/import/get-menu-swiggy?srsid=${swiggyId}&rsid=${selectedRestaurant}`;
  
    try {
      const response = await axios.get(url, { responseType: "blob" });
      const restaurantName =
        restaurants
          .find((r) => r.id === selectedRestaurant)
          ?.title.replace(/[^a-zA-Z0-9]/g, "_") || "restaurant";
      const timestamp = Date.now();
      const filename = `${restaurantName}_swiggy_menu_${timestamp}.xlsx`;
  
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
  
      showAlert("Swiggy menu Excel downloaded successfully!");
    } catch (error) {
      console.error("Error downloading Swiggy menu Excel:", error);
      showAlert("Failed to download Swiggy menu Excel.");
    }
  };

  const addNewVariation = (menuItemIndex) => {
    const updatedItems = [...menuItems];
    updatedItems[menuItemIndex].variations.push({
        id: generateVariationId("variation"),
        name: "",
        price: "",
        default: false,
        isEnabled: true
    });
    setMenuItems(updatedItems);
};


  return (
    <Box sx={{ mb: 2, width: "100%" }}>
      <Tabs
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
        sx={{ marginBottom: "10px" }}
      >
        <Tab label="Grid" />
        <Tab label="Excel" />
        <Tab label="Swiggy Import" />
      </Tabs>

      {activeTab === 0 && (
        <>
          <FormControl sx={{ mb: 2, width: "25%" }}>
            <InputLabel>Restaurant</InputLabel>
            <Select
              value={selectedRestaurant}
              onChange={(e) => setSelectedRestaurant(e.target.value)}
            >
              {restaurants.map((restaurant) => (
                <MenuItem key={restaurant.id} value={restaurant.id}>
                  {restaurant.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={saveMenuItems}
            sx={{ mb: 2, ml: 2 }}
          >
            Save
          </Button>
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Veg</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Recommend</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Image URL</TableCell>
                  <TableCell>Variations</TableCell>
                  <TableCell>Order Type</TableCell>
                  <TableCell>Preparation Time</TableCell>
                  <TableCell>Discount Type</TableCell>
                  <TableCell>Discount Value</TableCell>
                  <TableCell>Tags</TableCell>
                  {/* <TableCell>Tags (JSON)</TableCell> */}
                  <TableCell
                    align="center"
                    sx={{ position: "sticky", right: 0, background: "#fff" }}
                  >
                    Remove
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {menuItems.map((item, index) => (
                  <React.Fragment key={item.id}>
                    <TableRow
                      sx={{ "& .MuiTableCell-root": { padding: "4px 8px" } }}
                    >
                      <TableCell sx={{ minWidth: "200px" }}>
                        <TextField
                          value={item.name}
                          onChange={(e) =>
                            handleFieldChange(index, "name", e.target.value)
                          }
                          fullWidth
                          sx={{ mb: 1, "& .MuiInputBase-root": { height: 30 } }}
                          required
                          error={!!errors[`${index}-name`]}
                          helperText={errors[`${index}-name`] || ""}
                        />
                      </TableCell>
                      <TableCell sx={{ minWidth: "170px" }}>
                        <TextField
                          value={item.price}
                          onChange={(e) =>
                            handleFieldChange(index, "price", e.target.value)
                          }
                          type="number"
                          fullWidth
                          sx={{ mb: 1, "& .MuiInputBase-root": { height: 30 } }}
                          required
                          error={!!errors[`${index}-price`]}
                          helperText={errors[`${index}-price`] || ""}
                        />
                      </TableCell>
                      <TableCell sx={{ minWidth: "200px" }}>
                        <TextField
                          value={item.description}
                          onChange={(e) =>
                            handleFieldChange(
                              index,
                              "description",
                              e.target.value
                            )
                          }
                          fullWidth
                          sx={{ mb: 1, "& .MuiInputBase-root": { height: 30 } }}
                        />
                      </TableCell>
                      <TableCell>
                        <Select
                          value={item.isVeg.toString()}
                          onChange={(e) =>
                            handleFieldChange(
                              index,
                              "isVeg",
                              e.target.value === "true"
                            )
                          }
                          fullWidth
                          sx={{ fontSize: "13px", padding: "4px 8px" }}
                        >
                          <MenuItem value="true">True</MenuItem>
                          <MenuItem value="false">False</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell>
                        <Select
                          value={item.active.toString()}
                          onChange={(e) =>
                            handleFieldChange(
                              index,
                              "active",
                              e.target.value === "true"
                            )
                          }
                          fullWidth
                          sx={{ fontSize: "13px", padding: "4px 8px" }}
                        >
                          <MenuItem value="true">True</MenuItem>
                          <MenuItem value="false">False</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell>
                        <Select
                          value={item.isRecommend.toString()}
                          onChange={(e) =>
                            handleFieldChange(
                              index,
                              "isRecommend",
                              e.target.value === "true"
                            )
                          }
                          fullWidth
                          sx={{ fontSize: "13px", padding: "4px 8px" }}
                        >
                          <MenuItem value="true">True</MenuItem>
                          <MenuItem value="false">False</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell sx={{ minWidth: "250px" }}>
                        <FormControl
                          fullWidth
                          required
                          error={!!errors[`${index}-categoryId`]}
                        >
                          <InputLabel>Category</InputLabel>
                          <Select
                            value={item.categoryId || ""}
                            onChange={(e) =>
                              handleFieldChange(
                                index,
                                "categoryId",
                                e.target.value
                              )
                            }
                          >
                            {categories.map((category) => (
                              <MenuItem key={category.id} value={category.id}>
                                {category.title}
                              </MenuItem>
                            ))}
                          </Select>
                          <Typography variant="caption" color="error">
                            {errors[`${index}-categoryId`] || ""}
                          </Typography>
                        </FormControl>
                      </TableCell>
                      <TableCell sx={{ minWidth: "200px" }}>
                        <Button
                          variant="contained"
                          component="label"
                          sx={{ fontSize: "12px", padding: "4px 8px" }}
                        >
                          Upload Image
                          <input
                            type="file"
                            hidden
                            onChange={(e) =>
                              handleImageUpload(index, e.target.files[0])
                            }
                          />
                        </Button>
                        {item.image && (
                          <a
                            onClick={() => window.open(item.image, "_blank")}
                            style={{
                              marginLeft: "8px",
                              fontSize: "12px",
                              textDecoration: "underline",
                            }}
                          >
                            View Image
                          </a>
                        )}
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={!!item.variations.length}
                          onChange={() => handleVariationToggle(index)}
                        />
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "repeat(2, 1fr)",
                            gap: "4px",
                          }}
                        >
                          {["Delivery", "Pick Up", "Dine In", "Online"].map(
                            (option) => (
                              <FormControlLabel
                                key={option}
                                control={
                                  <Checkbox
                                    checked={item.ordertype.includes(option)}
                                    onChange={() =>
                                      handleOrderTypeChange(index, option)
                                    }
                                  />
                                }
                                label={option}
                                sx={{
                                  "& .MuiFormControlLabel-label": {
                                    fontSize: "12px",
                                  },
                                }}
                              />
                            )
                          )}
                        </Box>
                      </TableCell>
                      <TableCell sx={{ minWidth: "150px" }}>
                        <TextField
                          value={item.preparationtime}
                          onChange={(e) =>
                            handleFieldChange(
                              index,
                              "preparationtime",
                              e.target.value
                            )
                          }
                          fullWidth
                          sx={{ mb: 1, "& .MuiInputBase-root": { height: 30 } }}
                        />
                      </TableCell>
                      <TableCell sx={{ minWidth: "150px" }}>
                        <TextField
                          value={item.discount_type}
                          onChange={(e) =>
                            handleFieldChange(
                              index,
                              "discount_type",
                              e.target.value
                            )
                          }
                          fullWidth
                          sx={{ mb: 1, "& .MuiInputBase-root": { height: 30 } }}
                        />
                      </TableCell>
                      <TableCell sx={{ minWidth: "150px" }}>
                        <TextField
                          value={item.discount_value}
                          onChange={(e) =>
                            handleFieldChange(
                              index,
                              "discount_value",
                              e.target.value
                            )
                          }
                          type="number"
                          fullWidth
                          sx={{ mb: 1, "& .MuiInputBase-root": { height: 30 } }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Checkbox onChange={() => openTagModal(index)} checked={!!item.tags?.meal_period} />
                      </TableCell>
                      {/* <TableCell sx={{ minWidth: '150px' }}>
              <TextField
                value={item.tags}
                onChange={(e) => handleFieldChange(index, 'tags', e.target.value)}
                fullWidth
                sx={{ mb: 1, '& .MuiInputBase-root': { height: 30 } }}
              />
            </TableCell> */}
                      <TableCell
                        align="center"
                        sx={{
                          position: "sticky",
                          right: 0,
                          background: "#fff",
                        }}
                      >
                        <IconButton
                          color="secondary"
                          onClick={() => removeRow(index)}
                          disabled={menuItems.length === 1}
                        >
                          <CloseIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    {item.variations.length > 0 && (
    <TableRow>
        <TableCell colSpan={12}>
            <Box sx={{ pl: 2, pt: 1, pb: 1 }}>
                {item.variations.map((variation, vIndex) => (
                    <Box key={variation.id} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                        <TextField
                            value={variation.name}
                            label="Variation Name"
                            onChange={(e) =>
                                handleNestedFieldChange(index, vIndex, "name", e.target.value)
                            }
                            size="small"
                        />
                        <TextField
                            value={variation.price}
                            label="Price"
                            onChange={(e) =>
                                handleNestedFieldChange(index, vIndex, "price", e.target.value)
                            }
                            type="number"
                            size="small"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={variation.default}
                                    onChange={(e) =>
                                        handleNestedFieldChange(index, vIndex, "default", e.target.checked)
                                    }
                                />
                            }
                            label="Default"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={variation.isEnabled}
                                    onChange={(e) =>
                                        handleNestedFieldChange(index, vIndex, "isEnabled", e.target.checked)
                                    }
                                />
                            }
                            label="Enabled"
                        />
                        <IconButton
                            color="secondary"
                            size="small"
                            onClick={() => removeVariation(index, vIndex)}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Box>
                ))}
                <Button onClick={() => addNewVariation(index)} variant="outlined" size="small">
                    Add Variation
                </Button>
            </Box>
        </TableCell>
    </TableRow>
)}

                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <IconButton color="primary" onClick={addNewRow} sx={{ mt: 2 }}>
            <AddIcon />
          </IconButton>
        </>
      )}

      {activeTab === 1 && (
        <>
          <FormControl sx={{ mb: 2, width: "25%" }}>
            <InputLabel>Restaurant</InputLabel>
            <Select
              value={selectedRestaurant}
              onChange={(e) => setSelectedRestaurant(e.target.value)}
            >
              {restaurants.map((restaurant) => (
                <MenuItem key={restaurant.id} value={restaurant.id}>
                  {restaurant.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: "flex", gap: 2, alignItems: "center", mb: 2 }}>
            <Button variant="contained" onClick={handleDownloadExcel}>
              Download Excel Template
            </Button>
            <Button
              variant="contained"
              component="label"
              disabled={isExcelUploading}
            >
              {isExcelUploading ? "Uploading..." : "Upload Excel"}
              <input type="file" hidden onChange={handleExcelUpload} />
            </Button>
          </Box>
        </>
      )}

      {activeTab === 2 && (
        <Box sx={{ mt: 2 }}>
          <FormControl sx={{ mb: 2, width: "25%", mr: 2 }}>
            <InputLabel>Restaurant</InputLabel>
            <Select
              value={selectedRestaurant}
              onChange={(e) => setSelectedRestaurant(e.target.value)}
            >
              {restaurants.map((restaurant) => (
                <MenuItem key={restaurant.id} value={restaurant.id}>
                  {restaurant.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Swiggy ID"
            value={swiggyId}
            onChange={(e) => setSwiggyId(e.target.value)}
            sx={{ mb: 2, width: "25%" }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSwiggyImport}
            sx={{ mb: 2, ml: 2 }}
          >
            Import from Swiggy
          </Button>
        </Box>
      )}

<Modal open={tagModalOpen} onClose={() => setTagModalOpen(false)}>
        <Box sx={{ padding: 3, backgroundColor: 'white', maxWidth: 500, margin: 'auto', mt: 5, maxHeight: 750, overflow : 'scroll' }}>
          <Typography variant="h6">Configure Tags</Typography>
          {[
            { label: 'Meal Period', field: 'meal_period', options: meal_period },
            { label: 'Dietary Info', field: 'dietary_info', options: dietary_info },
            { label: 'Allergens', field: 'allergens', options: allergens },
            { label: 'Taste', field: 'taste', options: taste },
            { label: 'Preparation Method', field: 'preparation_method', options: preparation_method },
            { label: 'Cuisines', field: 'cuisines', options: cuisines },
            { label: 'Serving Temp', field: 'serving_temp', options: serving_temp },
          ].map(({ label, field, options }) => (
            <FormControl fullWidth sx={{ mt: 2 }} key={field}>
              <InputLabel>{label}</InputLabel>
              <Select
                multiple
                value={tagData[field]}
                onChange={(e) => handleTagChange(field, e.target.value)}
              >
                {options.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ))}
          <TextField
            label="Calories"
            type="number"
            value={tagData.calories || ''}
            onChange={(e) => handleTagChange('calories', Number(e.target.value))}
            fullWidth
            sx={{ mt: 2 }}
          />
          <TextField
            label="Serving Size"
            type="number"
            value={tagData.serving_size || ''}
            onChange={(e) => handleTagChange('serving_size', Number(e.target.value))}
            fullWidth
            sx={{ mt: 2 }}
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Contains Alcohol</InputLabel>
            <Select
              value={tagData.contains_alcohol ?? ''}
              onChange={(e) => handleTagChange('contains_alcohol', e.target.value)}
            >

              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Spice Level"
            type="number"
            value={tagData.spice_level || ''}
            onChange={(e) => handleTagChange('spice_level', Number(e.target.value))}
            fullWidth
            sx={{ mt: 2 }}
          />
          <TextField
            label="Ingredients (Comma-separated)"
            value={(tagData.ingredients || []).join(', ')}
            onChange={(e) =>
              handleTagChange(
                'ingredients',
                e.target.value.split(',').map((v) => v.trim())
              )
            }
            fullWidth
            sx={{ mt: 2 }}
          />
          <TextField
            label="Preparation Time (minutes)"
            type="number"
            fullWidth
            value={tagData.preparation_time}
            onChange={(e) =>
              handleTagChange("preparation_time", Number(e.target.value))
            }
          />

          <Button variant="contained" onClick={saveTagData} sx={{ mt: 2 }}>
            Save
          </Button>
          <Button onClick={() => setTagModalOpen(false)} sx={{ mt: 2, ml: 1 }}>
            Cancel
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={openAlert}
        autoHideDuration={3000}
        onClose={() => setOpenAlert(false)}
      >
        <Alert
          onClose={() => setOpenAlert(false)}
          severity="info"
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddMenuItems;
