import React, { useState, useEffect } from 'react';
import { Box, Container, FormControl, Select, MenuItem, Tabs, Tab } from '@mui/material';
import axios from 'axios';
import UpdateRecommendations from './UpdateRecommendations';
import AddRecommendations from './AddRecommendations';

const RecommendedItems = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [restaurantId, setRestaurantId] = useState('');
  const [activeTab, setActiveTab] = useState(0); // 0 = Update Recommendations, 1 = Add Recommendations

  useEffect(() => {
    fetchRestaurants();
  }, []);

  const fetchRestaurants = async () => {
    try {
      const response = await axios.get('https://backend-cheeron.au-ki.com/onboard/restaurants');
      setRestaurants(response.data);
      if (response.data.length > 0) {
        setRestaurantId(response.data[0].id);
      }
    } catch (error) {
      console.error('Error fetching restaurants:', error);
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ position: 'sticky', top: 64, background: '#fff', zIndex: 1000, pb: 2, pt: 2 }}>
        {/* Common Restaurant Dropdown */}
        <FormControl sx={{ width: '40%' }}>
          <Select
            value={restaurantId}
            onChange={(e) => setRestaurantId(e.target.value)}
            displayEmpty
            sx={{ height: '40px', fontSize: '14px' }}
          >
            <MenuItem value="" disabled>Select Restaurant</MenuItem>
            {restaurants.map((restaurant) => (
              <MenuItem key={restaurant.id} value={restaurant.id}>
                {restaurant.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Tabs for Update & Add Recommendations */}
      <Tabs
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
        centered
        textColor="inherit"
        indicatorColor="secondary"
        sx={{ marginBottom: 2 }}
      >
        <Tab label="Update Recommendations" />
        <Tab label="Add Recommendations" />
      </Tabs>

      {activeTab === 0 && <UpdateRecommendations restaurantId={restaurantId} />}
      {activeTab === 1 && <AddRecommendations restaurantId={restaurantId} />}
    </Container>
  );
};

export default RecommendedItems;
