import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Switch,
  CircularProgress,
  Snackbar,
  Alert,
  Tabs,
  Tab,
} from '@mui/material';
import axios from 'axios';
import { FormGroup, FormControlLabel } from '@mui/material';

const defaultCaptainFormFields = {
  getName: true,
  getPhoneNumber: true,
  getNumberOfPeople: true,
  getLifeStyle: false,
  getAgeGroup: false,
  getOccasion: false,
  getPaymentMethod: false,
  redirectToCustomerInfo: false,
  delayNotifyCaptain: false,
  redirectToPetpoojaCaptain: true,
  isReservation: false,

};

const RestaurantForm = () => {
  const [formData, setFormData] = useState({
    title: '',
    address: '',
    gst_number: '',
    logo: '',
    bg: '',
    time: 0,
    isgstincluded: false,
    store_status: true,
    isPetpoojaEnabled: false,
    petpoojaMappingId: '',
    petpoojaAppKey: '',         // New field for Petpooja App Key
    petpoojaAppSecret: '',      // New field for Petpooja App Secret
    petpoojaAccessToken: '',    // New field for Petpooja Access Token
    isservicecharge: false,
    servicechargepercent: 0.0,
    sendPhoneNumber: false,
    usecolor: false,
    isbutlerdiscount: false,
    captain_form_fields: defaultCaptainFormFields,
    newNotificationFlow: false,
  });
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [activeTab, setActiveTab] = useState(0); // Tabs for Add and Update functionality
  const [isSyncingDb, setIsSyncingDb] = useState(false);
  const [isSyncingTables, setIsSyncingTables] = useState(false);
  const [syncDbMessage, setSyncDbMessage] = useState("");
  const [syncTablesMessage, setSyncTablesMessage] = useState("");

  const showAlert = (message) => {
    setAlertMessage(message);
    setOpenAlert(true);
  };

  // Fetch restaurants and normalize Petpooja fields from the DB
  const fetchRestaurants = async () => {
    try {
      const response = await axios.get('https://backend-cheeron.au-ki.com/onboard/restaurants');
      const normalizedRestaurants = response.data.map((restaurant) => ({
        ...restaurant,
        isPetpoojaEnabled: restaurant.ispetpoojaenabled,
        petpoojaMappingId: restaurant.petpoojamappingid,
        petpoojaAppKey: restaurant.petpooja_app_key,
        petpoojaAppSecret: restaurant.petpooja_app_secret,
        petpoojaAccessToken: restaurant.petpooja_access_token,
        isservicecharge: restaurant.isservicecharge,
        servicechargepercent: restaurant.servicechargepercent,
        usecolor: restaurant.usecolor,
        isbutlerdiscount: restaurant.isbutlerdiscount,
        captain_form_fields: restaurant.captain_form_fields || defaultCaptainFormFields,
        newNotificationFlow: restaurant.flags?.newNotificationFlow || false,

      }));
      setRestaurants(normalizedRestaurants);
    } catch (error) {
      console.error('Error fetching restaurants:', error);
      showAlert('Error fetching restaurants');
    }
  };

  const handleRestaurantSelection = (restaurantId) => {
    const selected = restaurants.find((r) => r.id === restaurantId);
    if (selected) {
      setFormData({
        title: selected.title,
        address: selected.address,
        gst_number: selected.gst_number,
        logo: selected.logo,
        bg: selected.bg,
        time: selected.time,
        isgstincluded: selected.isgstincluded,
        store_status: selected.store_status,
        isPetpoojaEnabled: selected.ispetpoojaenabled,
        petpoojaMappingId: selected.ispetpoojaenabled ? selected.petpoojamappingid : '',
        petpoojaAppKey: selected.ispetpoojaenabled ? selected.petpooja_app_key : '',
        petpoojaAppSecret: selected.ispetpoojaenabled ? selected.petpooja_app_secret : '',
        petpoojaAccessToken: selected.ispetpoojaenabled ? selected.petpooja_access_token : '',
        isservicecharge: selected.isservicecharge ?? false,
        servicechargepercent: selected.isservicecharge ? selected.servicechargepercent : selected.servicechargepercent ?? 0.0,
        sendPhoneNumber: selected.sendphonenumber,
        usecolor: selected.usecolor,
        isbutlerdiscount: selected.isbutlerdiscount,
        captain_form_fields: selected.captain_form_fields || defaultCaptainFormFields,
        newNotificationFlow: selected.newNotificationFlow,
        open_time: selected.open_time || '',
        close_time: selected.close_time || '',

      });
      setSelectedRestaurant(restaurantId);
    }
  };

  const handleAddRestaurant = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const id = generateId(formData.title); // Generate a unique ID for the restaurant
      const payload = {
        id,
        ...formData,
        // Only send Petpooja fields if integration is enabled
        petpoojamappingid: formData.isPetpoojaEnabled ? formData.petpoojaMappingId : null,
        petpooja_app_key: formData.isPetpoojaEnabled ? formData.petpoojaAppKey : null,
        petpooja_app_secret: formData.isPetpoojaEnabled ? formData.petpoojaAppSecret : null,
        petpooja_access_token: formData.isPetpoojaEnabled ? formData.petpoojaAccessToken : null,
        gst_number: formData.gst_number,
        usecolor: formData.usecolor,
        isbutlerdiscount: formData.isbutlerdiscount,
        flags: {
          newNotificationFlow: formData.newNotificationFlow,
        },
      };
      const response = await axios.post('https://backend-cheeron.au-ki.com/update/create-Restaurant', payload);

      if (response.status === 201) {
        showAlert('Restaurant added successfully!');
        setFormData({
          title: '',
          address: '',
          gst_number: '',
          logo: '',
          bg: '',
          time: 0,
          isgstincluded: false,
          store_status: true,
          isPetpoojaEnabled: false,
          petpoojaMappingId: '',
          petpoojaAppKey: '',
          petpoojaAppSecret: '',
          petpoojaAccessToken: '',
          usecolor: false,
          isbutlerdiscount: false,
          newNotificationFlow: false,
        });
        fetchRestaurants(); // Refresh the restaurant list after adding a new one
      } else {
        throw new Error('Failed to add restaurant');
      }
    } catch (error) {
      console.error('Error adding restaurant:', error);
      showAlert('Failed to add restaurant.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateRestaurant = async () => {
    try {
      setIsLoading(true);
      const existingRestaurant = restaurants.find(r => r.id === selectedRestaurant) || {};

      // Preserve existing values when toggles are disabled
      const updatedPetpoojaMappingId = formData.isPetpoojaEnabled
        ? formData.petpoojaMappingId
        : existingRestaurant.petpoojamappingid || "";
      const updatedPetpoojaAppKey = formData.isPetpoojaEnabled
        ? formData.petpoojaAppKey
        : existingRestaurant.petpooja_app_key || "";
      const updatedPetpoojaAppSecret = formData.isPetpoojaEnabled
        ? formData.petpoojaAppSecret
        : existingRestaurant.petpooja_app_secret || "";
      const updatedPetpoojaAccessToken = formData.isPetpoojaEnabled
        ? formData.petpoojaAccessToken
        : existingRestaurant.petpooja_access_token || "";
      const updatedServiceChargePercent = formData.isservicecharge
        ? formData.servicechargepercent
        : existingRestaurant.servicechargepercent || 0.0;

      const payload = {
        restaurantId: selectedRestaurant,
        ...formData,
        open_time: formData.open_time || null,
        close_time: formData.close_time || null,
        petpoojamappingid: updatedPetpoojaMappingId,
        petpooja_app_key: updatedPetpoojaAppKey,
        petpooja_app_secret: updatedPetpoojaAppSecret,
        petpooja_access_token: updatedPetpoojaAccessToken,
        servicechargepercent: updatedServiceChargePercent,
        gst_number: formData.gst_number,
        usecolor: formData.usecolor,
        isbutlerdiscount: formData.isbutlerdiscount,
        captain_form_fields: formData.captain_form_fields,
        flags: {
          newNotificationFlow: formData.newNotificationFlow,
        },

      };

      await axios.put('https://backend-cheeron.au-ki.com/update/update-restaurant-status', payload);
      showAlert('Restaurant updated successfully!');
    } catch (error) {
      console.error('Error updating restaurant:', error);
      showAlert('Failed to update restaurant.');
    } finally {
      setIsLoading(false);
    }
  };

  const generateId = (title) => {
    const cleanedTitle = title.replace(/[=,*&^%$#@!]/g, '');
    const words = cleanedTitle.split(' ');
    if (words.length === 1) {
      return words[0].charAt(0).toLowerCase() + words[0].slice(1);
    } else {
      return words.map((word, index) => {
        if (index === 0) {
          return word.charAt(0).toLowerCase() + word.slice(1);
        }
        return word;
      }).join('');
    }
  };

  useEffect(() => {
    fetchRestaurants();
  }, []);

  useEffect(() => {
    if (activeTab === 0) {
      setFormData({
        title: '',
        address: '',
        gst_number: '',
        logo: '',
        bg: '',
        time: 0,
        isgstincluded: false,
        store_status: true,
        isPetpoojaEnabled: false,
        petpoojaMappingId: '',
        petpoojaAppKey: '',
        petpoojaAppSecret: '',
        petpoojaAccessToken: '',
      });
      setSelectedRestaurant('');
    }
  }, [activeTab]);

  const handleSyncDb = async () => {
    if (!selectedRestaurant) return;

    setIsSyncingDb(true);
    setSyncDbMessage("");

    try {
      await axios.post(`https://backend-cheeron.au-ki.com/petpooja/sync-db/${selectedRestaurant}`);
      setSyncDbMessage("Synced DB");
    } catch (error) {
      console.error("Error syncing DB:", error);
      setSyncDbMessage("Failed to Sync DB");
    }

    setIsSyncingDb(false);
  };

  const handleSyncTables = async () => {
    if (!selectedRestaurant) return;

    setIsSyncingTables(true);
    setSyncTablesMessage("");

    try {
      await axios.post(`https://backend-cheeron.au-ki.com/petpooja/sync-tables/${selectedRestaurant}`);
      setSyncTablesMessage("Synced Table");
    } catch (error) {
      console.error("Error syncing Tables:", error);
      setSyncTablesMessage("Failed to Sync Tables");
    }
    setIsSyncingTables(false);
  };

  return (
    <Container maxWidth="sm">
      <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)} centered>
        <Tab label="Add New Restaurant" />
        <Tab label="Update Restaurant" />
      </Tabs>

      {activeTab === 0 && (
        <Box
          component="form"
          onSubmit={handleAddRestaurant}
          sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <TextField
            label="Title"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            fullWidth
          />
          <TextField
            label="Address"
            value={formData.address}
            onChange={(e) => setFormData({ ...formData, address: e.target.value })}
            fullWidth
          />
          <TextField
            label="GST Number"
            value={formData.gst_number}
            onChange={(e) => setFormData({ ...formData, gst_number: e.target.value })}
            fullWidth
          />
          <Typography>Is GST Included?</Typography>
          <Switch
            checked={formData.isgstincluded}
            onChange={(e) => setFormData({ ...formData, isgstincluded: e.target.checked })}
          />
          <Typography>Is Store Open?</Typography>
          <Switch
            checked={formData.store_status}
            onChange={(e) => setFormData({ ...formData, store_status: e.target.checked })}
          />
          <Typography>Enable Petpooja Integration?</Typography>
          <Switch
            checked={formData.isPetpoojaEnabled}
            onChange={(e) =>
              setFormData({
                ...formData,
                isPetpoojaEnabled: e.target.checked,
                petpoojaMappingId: e.target.checked ? formData.petpoojaMappingId : '',
                petpoojaAppKey: e.target.checked ? formData.petpoojaAppKey : '',
                petpoojaAppSecret: e.target.checked ? formData.petpoojaAppSecret : '',
                petpoojaAccessToken: e.target.checked ? formData.petpoojaAccessToken : '',
              })
            }
          />
          {formData.isPetpoojaEnabled && (
            <>
              <TextField
                label="Petpooja Mapping ID"
                value={formData.petpoojaMappingId}
                onChange={(e) => setFormData({ ...formData, petpoojaMappingId: e.target.value })}
                fullWidth
                required
              />
              <TextField
                label="Petpooja App Key"
                value={formData.petpoojaAppKey}
                onChange={(e) => setFormData({ ...formData, petpoojaAppKey: e.target.value })}
                fullWidth
                required
              />
              <TextField
                label="Petpooja App Secret"
                value={formData.petpoojaAppSecret}
                onChange={(e) => setFormData({ ...formData, petpoojaAppSecret: e.target.value })}
                fullWidth
                required
              />
              <TextField
                label="Petpooja Access Token"
                value={formData.petpoojaAccessToken}
                onChange={(e) => setFormData({ ...formData, petpoojaAccessToken: e.target.value })}
                fullWidth
                required
              />
            </>
          )}
          <Typography>Enable Service Charge?</Typography>
          <Switch
            checked={formData.isservicecharge}
            onChange={(e) =>
              setFormData({
                ...formData,
                isservicecharge: e.target.checked,
                servicechargepercent: e.target.checked ? formData.servicechargepercent : 0.0,
              })
            }
          />
          {formData.isservicecharge && (
            <TextField
              label="Service Charge Percentage"
              type="number"
              value={formData.servicechargepercent}
              onChange={(e) =>
                setFormData({ ...formData, servicechargepercent: parseFloat(e.target.value) || 0.0 })
              }
              fullWidth
              required
            />
          )}
          <Typography>Enable table colors for captain app?</Typography>
          <Switch
            checked={formData.usecolor}
            onChange={(e) => setFormData({ ...formData, usecolor: e.target.checked })}
          />
          <Typography>Enable Butler Discount?</Typography>
          <Switch
            checked={formData.isbutlerdiscount}
            onChange={(e) => setFormData({ ...formData, isbutlerdiscount: e.target.checked })}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Add Restaurant'}
          </Button>
        </Box>
      )}

      {activeTab === 1 && (
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <FormControl fullWidth>
            <InputLabel>Select Restaurant</InputLabel>
            <Select
              value={selectedRestaurant}
              onChange={(e) => handleRestaurantSelection(e.target.value)}
            >
              {restaurants.map((restaurant) => (
                <MenuItem key={restaurant.id} value={restaurant.id}>
                  {restaurant.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedRestaurant && (
            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                label="Title"
                value={formData.title}
                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                fullWidth
              />
              <TextField
                label="Address"
                value={formData.address}
                onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                fullWidth
              />
              <TextField
                label="GST Number"
                value={formData.gst_number}
                onChange={(e) => setFormData({ ...formData, gst_number: e.target.value })}
                fullWidth
              />
              <Typography>Is GST Included?</Typography>
              <Switch
                checked={formData.isgstincluded}
                onChange={(e) => setFormData({ ...formData, isgstincluded: e.target.checked })}
              />
              <Typography>Is Store Open?</Typography>
              <Switch
                checked={formData.store_status}
                onChange={(e) => setFormData({ ...formData, store_status: e.target.checked })}
              />
              <Typography>Enable Petpooja Integration?</Typography>
              <Switch
                checked={formData.isPetpoojaEnabled}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    isPetpoojaEnabled: e.target.checked,
                    petpoojaMappingId: e.target.checked ? formData.petpoojaMappingId : '',
                    petpoojaAppKey: e.target.checked ? formData.petpoojaAppKey : '',
                    petpoojaAppSecret: e.target.checked ? formData.petpoojaAppSecret : '',
                    petpoojaAccessToken: e.target.checked ? formData.petpoojaAccessToken : '',
                  })
                }
              />
              {formData.isPetpoojaEnabled && (
                <>
                  <TextField
                    label="Petpooja Mapping ID"
                    value={formData.petpoojaMappingId}
                    onChange={(e) => setFormData({ ...formData, petpoojaMappingId: e.target.value })}
                    fullWidth
                    required
                  />
                  <TextField
                    label="Petpooja App Key"
                    value={formData.petpoojaAppKey}
                    onChange={(e) => setFormData({ ...formData, petpoojaAppKey: e.target.value })}
                    fullWidth
                    required
                  />
                  <TextField
                    label="Petpooja App Secret"
                    value={formData.petpoojaAppSecret}
                    onChange={(e) => setFormData({ ...formData, petpoojaAppSecret: e.target.value })}
                    fullWidth
                    required
                  />
                  <TextField
                    label="Petpooja Access Token"
                    value={formData.petpoojaAccessToken}
                    onChange={(e) => setFormData({ ...formData, petpoojaAccessToken: e.target.value })}
                    fullWidth
                    required
                  />
                  <Typography>Send phone number to Petpooja?</Typography>
                  <Switch
                    checked={formData.sendPhoneNumber}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        sendPhoneNumber: e.target.checked
                      })
                    }
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSyncDb}
                    disabled={isSyncingDb}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {isSyncingDb ? <CircularProgress size={24} /> : "Sync DB"}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSyncTables}
                    disabled={isSyncingTables}
                    sx={{ mt: 1 }}
                  >
                    {isSyncingTables ? <CircularProgress size={24} /> : "Sync Tables"}
                  </Button>
                  {syncDbMessage && (
                    <Typography variant="body2" color="success" sx={{ mt: 1 }}>
                      {syncDbMessage}
                    </Typography>
                  )}
                  {syncTablesMessage && (
                    <Typography variant="body2" color="success" sx={{ mt: 1 }}>
                      {syncTablesMessage}
                    </Typography>
                  )}
                </>
              )}
              <Typography>Enable Service Charge?</Typography>
              <Switch
                checked={formData.isservicecharge}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    isservicecharge: e.target.checked,
                    servicechargepercent: e.target.checked ? formData.servicechargepercent : 0.0,
                  })
                }
              />
              {formData.isservicecharge && (
                <TextField
                  label="Service Charge Percentage"
                  type="number"
                  value={formData.servicechargepercent}
                  onChange={(e) =>
                    setFormData({ ...formData, servicechargepercent: parseFloat(e.target.value) || 0.0 })
                  }
                  fullWidth
                  required
                />
              )}

              <Typography>Enable table colors for captain app?</Typography>
              <Switch
                checked={formData.usecolor}
                onChange={(e) => setFormData({ ...formData, usecolor: e.target.checked })}
              />
              <Typography>Enable Butler Discount?</Typography>
              <Switch
                checked={formData.isbutlerdiscount}
                onChange={(e) => setFormData({ ...formData, isbutlerdiscount: e.target.checked })}
              />
              <Typography>Enable New Notification Flow?</Typography>
              <Switch
                checked={formData.newNotificationFlow}
                onChange={(e) =>
                  setFormData({ ...formData, newNotificationFlow: e.target.checked })
                }
              />

              {/*Section: Captain Form Fields Configuration */}
              <Box sx={{ border: '1px solid #ccc', borderRadius: 1, p: 2, mt: 2 }}>
                <Typography variant="h6">Captain App Configurations</Typography>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.captain_form_fields.getName}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            captain_form_fields: {
                              ...formData.captain_form_fields,
                              getName: e.target.checked,
                            },
                          })
                        }
                      />
                    }
                    label="Get Name"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.captain_form_fields.getPhoneNumber}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            captain_form_fields: {
                              ...formData.captain_form_fields,
                              getPhoneNumber: e.target.checked,
                            },
                          })
                        }
                      />
                    }
                    label="Get Phone Number"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.captain_form_fields.getNumberOfPeople}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            captain_form_fields: {
                              ...formData.captain_form_fields,
                              getNumberOfPeople: e.target.checked,
                            },
                          })
                        }
                      />
                    }
                    label="Get Number Of People"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.captain_form_fields.getLifeStyle}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            captain_form_fields: {
                              ...formData.captain_form_fields,
                              getLifeStyle: e.target.checked,
                            },
                          })
                        }
                      />
                    }
                    label="Get LifeStyle"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.captain_form_fields.getAgeGroup}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            captain_form_fields: {
                              ...formData.captain_form_fields,
                              getAgeGroup: e.target.checked,
                            },
                          })
                        }
                      />
                    }
                    label="Get Age Group"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.captain_form_fields.getOccasion}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            captain_form_fields: {
                              ...formData.captain_form_fields,
                              getOccasion: e.target.checked,
                            },
                          })
                        }
                      />
                    }
                    label="Get Occasion"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.captain_form_fields.getPaymentMethod}
                        onChange={e =>
                          setFormData({
                            ...formData,
                            captain_form_fields: {
                              ...formData.captain_form_fields,
                              getPaymentMethod: e.target.checked,
                            },
                          })
                        }
                      />
                    }
                    label="Get Payment Method"
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.captain_form_fields.redirectToCustomerInfo}
                        onChange={e =>
                          setFormData({
                            ...formData,
                            captain_form_fields: {
                              ...formData.captain_form_fields,
                              redirectToCustomerInfo: e.target.checked,
                            },
                          })
                        }
                      />
                    }
                    label="Redirect to Customer Info"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.captain_form_fields.delayNotifyCaptain}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            captain_form_fields: {
                              ...formData.captain_form_fields,
                              delayNotifyCaptain: e.target.checked,
                            },
                          })
                        }
                      />
                    }
                    label="Delay Notify Captain"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.captain_form_fields.redirectToPetpoojaCaptain}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            captain_form_fields: {
                              ...formData.captain_form_fields,
                              redirectToPetpoojaCaptain: e.target.checked,
                            },
                          })
                        }
                      />
                    }
                    label="Redirect to petpooja captain app on order completion?"
                  />

<FormControlLabel
                    control={
                      <Switch
                        checked={formData.captain_form_fields.isReservation}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            captain_form_fields: {
                              ...formData.captain_form_fields,
                              isReservation: e.target.checked,
                            },
                          })
                        }
                      />
                    }
                    label="Reservation Question?"
                  />



                </FormGroup>
              </Box>
              <TextField
                label="Open Time"
                type="time"
                value={formData.open_time}
                onChange={e => setFormData({ ...formData, open_time: e.target.value })}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
              <TextField
                label="Close Time"
                type="time"
                value={formData.close_time}
                onChange={e => setFormData({ ...formData, close_time: e.target.value })}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateRestaurant}
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : 'Update Restaurant'}
              </Button>
            </Box>
          )}
        </Box>
      )}

      <Snackbar open={openAlert} autoHideDuration={3000} onClose={() => setOpenAlert(false)}>
        <Alert onClose={() => setOpenAlert(false)} severity="info" sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default RestaurantForm;
