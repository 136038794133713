import React, { useState } from 'react';
import { Container, Typography, Box, ThemeProvider, createTheme, AppBar, Toolbar, Tabs, Tab, CssBaseline } from '@mui/material';
import MenuItemUpdate from './MenuItemUpdate';
import RestaurantForm from './RestaurantForm';
import AvailabilityUpdate from './AvailabilityUpdate';
import CategoryManagement from './CategoryManagement';
import AddMenuItems from './AddMenuItems.js'; 
import AddCategory from './AddCategory';
import AddTags from './AddTags';
import AddAddons from './AddAddons.js';
import ItemData from './ItemData';
import TablesAndAreas from './TablesAndAreas.js';
import CaptainManagement from './CaptainManagement.js';
import UserManagement from './UserManagement';
import Interaction from './Interaction';
import Feedback from './Feedback';
import RecommendedItems from './RecommendedItems';
import Analytics from './Analytics';
import PetpoojaCategoryManagement from './PetpoojaCategoryManagement';
import OrderData from "./OrderData";



const theme = createTheme({
  palette: {
    primary: { main: '#1976d2' },
    secondary: { main: '#f50057' },
    background: { default: '#f5f5f5' },
  },
  typography: {
    h4: { fontWeight: 600, marginBottom: '20px' },
    body1: { color: '#4a4a4a' },
  },
});

const App = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [menuTab, setMenuTab] = useState(0);  

  const handleTabChange = (event, newValue) => setActiveTab(newValue);
  const handleMenuTabChange = (event, newValue) => setMenuTab(newValue);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <AppBar position="static" color="primary">
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }} align="center">
              SUPPORT DASHBOARD
            </Typography>
          </Toolbar>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            centered
            textColor="inherit"
            indicatorColor="secondary"
          >
            {/* <Tab label="Item Data" /> */}
            <Tab label="Restaurant" />
            <Tab label="Menu Management" />
            <Tab label="Interaction" />
            <Tab label="Feedback" />
            <Tab label="User Management" />
            <Tab label="Analytics" />
            <Tab label="Order Data" /> 

          </Tabs>
        </AppBar>

        <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: theme.palette.background.default }}>
          <Container maxWidth="xl">
            {activeTab === 0 && <RestaurantForm />}
            {activeTab === 1 && (
              <Box>
                <Tabs
                  value={menuTab}
                  onChange={handleMenuTabChange}
                  centered
                  textColor="inherit"
                  indicatorColor="secondary"
                  sx={{ marginBottom: 2 }}
                >
                  <Tab label="Add Menu Items" />
                  <Tab label="Update Menu Item" />
                  <Tab label="Availability" />
                  <Tab label="Category" />
                  <Tab label="Petpooja Categories" />
                  <Tab label="Add Tags" />
                  <Tab label="Add Addons" />
                  <Tab label="Tables and Areas" />
                  <Tab label="Recommended Items" />
                  <Tab label="Captain Management" />
                </Tabs>
                {menuTab === 0 && <AddMenuItems />}
                {menuTab === 1 && <MenuItemUpdate />}
                {menuTab === 2 && <AvailabilityUpdate />}
                {menuTab === 3 && <CategoryManagement />}
                {menuTab === 4 && <PetpoojaCategoryManagement />}
                {menuTab === 5 && <AddTags />}
                {menuTab === 6 && <AddAddons />}
                {menuTab === 7 && <TablesAndAreas />}
                {menuTab === 8 && <RecommendedItems />}
                {menuTab === 9 && <CaptainManagement />}
              </Box>
            )}
            {/* {activeTab === 0 && <ItemData />} */}
            {activeTab === 2 && <Interaction />}
            {activeTab === 3 && <Feedback />}
             {activeTab === 4 && <UserManagement />}
             {activeTab === 5 && <Analytics />}
             {activeTab === 6 && <OrderData />} 


          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default App;
