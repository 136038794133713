import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TextField,
  Button,
} from '@mui/material';
import axios from 'axios';

const PetpoojaCategoryManagement = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState('');
  const [categories, setCategories] = useState([]);
  // Local copy for editing
  const [editedCategories, setEditedCategories] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  // Fetch restaurants for the dropdown
  const fetchRestaurants = async () => {
    try {
      const res = await axios.get('https://backend-cheeron.au-ki.com/onboard/restaurants');
      setRestaurants(res.data);
      if (res.data.length > 0) {
        setSelectedRestaurant(res.data[0].id);
      }
    } catch (error) {
      console.error('Error fetching restaurants:', error);
    }
  };

  // Fetch Petpooja categories from the new table for the selected restaurant
  const fetchCategories = async () => {
    if (!selectedRestaurant) return;
    try {
      const res = await axios.get('https://backend-cheeron.au-ki.com/onboard/petpooja-categories', {
        params: { restaurantId: selectedRestaurant },
      });
      // Convert null sort_order to an empty string for display/editing
      const cats = res.data.map(cat => ({
        ...cat,
        sort_order: cat.sort_order === null ? '' : cat.sort_order,
      }));
      setCategories(cats);
      setEditedCategories(cats);
    } catch (error) {
      console.error('Error fetching Petpooja categories:', error);
    }
  };

  useEffect(() => {
    fetchRestaurants();
  }, []);

  useEffect(() => {
    if (selectedRestaurant) {
      fetchCategories();
    }
  }, [selectedRestaurant]);

  // Handle change for sort_order in an edited row
  const handleSortOrderChange = (id, newSortOrder) => {
    setEditedCategories(prev =>
      prev.map(cat => (cat.id === id ? { ...cat, sort_order: newSortOrder } : cat))
    );
  };

  // Toggle edit mode (revert changes if canceling)
  const toggleEditMode = () => {
    if (isEditing) {
      // Cancel editing: revert changes
      setEditedCategories(categories);
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  };

  // Save all changes using a single batch update call
  const handleSaveAll = async () => {
    try {
      const updates = editedCategories.map(cat => ({
        id: cat.id,
        restaurantId: selectedRestaurant,
        // If the field is an empty string, send null; otherwise, convert to Number.
        sort_order: cat.sort_order === '' ? null : Number(cat.sort_order),
      }));
      await axios.put('https://backend-cheeron.au-ki.com/onboard/petpooja-categories/update', { updates });
      setIsEditing(false);
      fetchCategories();
      alert('Sort order updated successfully.');
    } catch (error) {
      console.error('Error updating sort orders:', error);
      alert('Error updating sort orders');
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ mb: 3 }}>
        <FormControl fullWidth>
          <InputLabel>Restaurant</InputLabel>
          <Select
            value={selectedRestaurant}
            onChange={(e) => setSelectedRestaurant(e.target.value)}
            fullWidth
          >
            {restaurants.map((r) => (
              <MenuItem key={r.id} value={r.id}>
                {r.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Petpooja Category Management
      </Typography>
      {editedCategories.length > 0 && (
        <Box sx={{ mb: 2 }}>
          {!isEditing ? (
            <Button variant="contained" onClick={toggleEditMode}>
              Edit
            </Button>
          ) : (
            <>
              <Button variant="contained" onClick={handleSaveAll} sx={{ mr: 1 }}>
                Save All
              </Button>
              <Button variant="outlined" onClick={toggleEditMode}>
                Cancel
              </Button>
            </>
          )}
        </Box>
      )}
      {editedCategories.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Category Name</TableCell>
                <TableCell>Sort Order</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {editedCategories.map((category) => (
                <TableRow key={category.id}>
                  <TableCell>{category.title}</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={category.sort_order !== '' ? category.sort_order : ''}
                      onChange={(e) => handleSortOrderChange(category.id, e.target.value)}
                      variant="outlined"
                      size="small"
                      disabled={!isEditing}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>No Petpooja categories available.</Typography>
      )}
    </Container>
  );
};

export default PetpoojaCategoryManagement;
