import React, { useState, useEffect } from 'react';
import {
  Box, Typography, TextField, Button, FormControl, InputLabel,
  Select, MenuItem, Paper, Table, TableBody, TableCell, TableHead, TableRow,
  Checkbox, FormControlLabel
} from '@mui/material';
import axios from 'axios';

const BASE_URL = 'https://backend-cheeron.au-ki.com';
const DEFAULT_PERMISSIONS = {
  menu_management: true,
  new_orders: true,
  completed_orders: true,
  printer_settings: false,
};
const PERMISSIONS = [
  { key: 'menu_management', label: 'Menu Management' },
  { key: 'new_orders', label: 'New Orders' },
  { key: 'completed_orders', label: 'Completed Orders' },
  { key: 'printer_settings', label: 'Printer Settings' },
];
const ROLES = ['captain','steward','manager'];

const CaptainManagement = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState('');
  const [captains, setCaptains] = useState([]);
  const [tables, setTables] = useState([]);

  const [newUsername, setNewUsername] = useState('');
  const [newPermissions, setNewPermissions] = useState({ ...DEFAULT_PERMISSIONS });
  const [newRole, setNewRole] = useState('captain');
  const [newAssignedTables, setNewAssignedTables] = useState([]);

  const [editingCaptainId, setEditingCaptainId] = useState(null);
  const [editingPermissions, setEditingPermissions] = useState({});
  const [editingRole, setEditingRole] = useState('');
  const [editingAssignedTables, setEditingAssignedTables] = useState([]);

  const allowAssign = restaurants.find(r => r.id === selectedRestaurant)?.allow_table_assignment;

  useEffect(() => { fetchRestaurants(); }, []);
  useEffect(() => {
    if (selectedRestaurant) {
      fetchCaptains();
      if (allowAssign) fetchTables();
    }
  }, [selectedRestaurant, allowAssign]);

  const fetchRestaurants = async () => {
    const { data } = await axios.get(`${BASE_URL}/onboard/restaurants`);
    setRestaurants(data);
    if (data.length) setSelectedRestaurant(data[0].id);
  };

  const fetchCaptains = async () => {
    const { data } = await axios.get(`${BASE_URL}/api/captains`, { params: { restaurant_id: selectedRestaurant } });
    setCaptains(data);
  };

  const fetchTables = async () => {
    const { data } = await axios.get(`${BASE_URL}/onboard/tables-areas/get-tables`, {
      params: { restaurantId: selectedRestaurant }
    });
    setTables(data);
  };

  const togglePermission = (key, setter) =>
    setter(prev => ({ ...prev, [key]: !prev[key] }));

  const toggleSelection = (tableNumber, setter) =>
    setter(prev => prev.includes(tableNumber) ? prev.filter(t => t !== tableNumber) : [...prev, tableNumber]);

  const handleCreateCaptain = async () => {
    if (!newUsername.trim()) return alert('Username is required');
    await axios.post(`${BASE_URL}/api/captains`, {
      username: newUsername,
      restaurant_id: selectedRestaurant,
      assigned_tables: allowAssign ? newAssignedTables : [],
      permissions: newPermissions,
      role: newRole
    });
    setNewUsername('');
    setNewPermissions({ ...DEFAULT_PERMISSIONS });
    setNewRole('captain');
    setNewAssignedTables([]);
    fetchCaptains();
  };

  const handleEditCaptain = c => {
    setEditingCaptainId(c.captain_id);
    setEditingPermissions(c.permissions || {});
    setEditingRole(c.role);
    setEditingAssignedTables(c.assigned_tables || []);
  };

  const handleUpdateCaptain = async id => {
    await axios.put(`${BASE_URL}/api/captains/${id}`, {
      assigned_tables: allowAssign ? editingAssignedTables : [],
      permissions: editingPermissions,
      role: editingRole
    });
    setEditingCaptainId(null);
    setEditingAssignedTables([]);
    fetchCaptains();
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5">Captain Management</Typography>

      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Restaurant</InputLabel>
        <Select value={selectedRestaurant} onChange={e => setSelectedRestaurant(e.target.value)}>
          {restaurants.map(r => <MenuItem key={r.id} value={r.id}>{r.title}</MenuItem>)}
        </Select>
      </FormControl>

      <Paper sx={{ p: 2, mb: 4 }}>
        <Typography variant="h6">Add New Captain</Typography>
        <TextField
          label="Username"
          fullWidth
          sx={{ my: 2 }}
          value={newUsername}
          onChange={e => setNewUsername(e.target.value)}
        />

        <FormControl fullWidth sx={{ mb:2 }}>
          <InputLabel>Role</InputLabel>
          <Select value={newRole} onChange={e => setNewRole(e.target.value)}>
            {ROLES.map(role => <MenuItem key={role} value={role}>{role}</MenuItem>)}
          </Select>
        </FormControl>

        {allowAssign && (
          <>
            <Typography>Assign Tables</Typography>
            <Box sx={{ display:'flex', flexWrap:'wrap', mb:2 }}>
              {tables.map(t => (
                <FormControlLabel key={t.id}
                  control={<Checkbox checked={newAssignedTables.includes(t.table_number)} onChange={() => toggleSelection(t.table_number, setNewAssignedTables)} />}
                  label={t.table_number}
                />
              ))}
            </Box>
          </>
        )}

        <Typography>Permissions</Typography>
        <Box sx={{ display:'flex', flexWrap:'wrap', mb:2 }}>
          {PERMISSIONS.map(p => (
            <FormControlLabel key={p.key}
              control={<Checkbox checked={newPermissions[p.key]} onChange={() => togglePermission(p.key, setNewPermissions)} />}
              label={p.label}
            />
          ))}
        </Box>

        <Button variant="contained" onClick={handleCreateCaptain}>Create Captain</Button>
      </Paper>

      <Paper sx={{ p: 2 }}>
        <Typography variant="h6">Captains</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Role</TableCell>
              {allowAssign && <TableCell>Assigned Tables</TableCell>}
              <TableCell>Permissions</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {captains.map(c => (
              <TableRow key={c.captain_id}>
                <TableCell>{c.captain_id}</TableCell>
                <TableCell>{c.username}</TableCell>
                <TableCell>
                  {editingCaptainId === c.captain_id ? (
                    <FormControl fullWidth>
                      <Select value={editingRole} onChange={e => setEditingRole(e.target.value)}>
                        {ROLES.map(role => <MenuItem key={role} value={role}>{role}</MenuItem>)}
                      </Select>
                    </FormControl>
                  ) : c.role}
                </TableCell>
                {allowAssign && (
                  <TableCell>
                    {editingCaptainId === c.captain_id ? (
                      <Box sx={{ display:'flex', flexWrap:'wrap' }}>
                        {tables.map(t => (
                          <FormControlLabel key={t.id}
                            control={<Checkbox checked={editingAssignedTables.includes(t.table_number)} onChange={() => toggleSelection(t.table_number, setEditingAssignedTables)} />}
                            label={t.table_number}
                          />
                        ))}
                      </Box>
                    ) : (c.assigned_tables || []).join(', ')}
                  </TableCell>
                )}
                <TableCell>
                  {editingCaptainId === c.captain_id ? (
                    <Box sx={{ display:'flex', flexWrap:'wrap' }}>
                      {PERMISSIONS.map(p => (
                        <FormControlLabel key={p.key}
                          control={<Checkbox checked={editingPermissions[p.key]} onChange={() => togglePermission(p.key, setEditingPermissions)} />}
                          label={p.label}
                        />
                      ))}
                    </Box>
                  ) : Object.entries(c.permissions || {}).filter(([_,v]) => v).map(([k]) => PERMISSIONS.find(p => p.key === k)?.label).join(', ')}
                </TableCell>
                <TableCell>
                  {editingCaptainId === c.captain_id ? (
                    <>
                      <Button size="small" onClick={() => handleUpdateCaptain(c.captain_id)}>Save</Button>
                      <Button size="small" onClick={() => setEditingCaptainId(null)}>Cancel</Button>
                    </>
                  ) : (
                    <Button size="small" onClick={() => handleEditCaptain(c)}>Edit</Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
};

export default CaptainManagement;
