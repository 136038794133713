import React from "react";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button } from "@mui/material";
import * as XLSX from "xlsx";

/**
 * Groups feedback data by date.
 */
const groupDataByDate = (data) => {
  return data.reduce((acc, row) => {
    const dateKey = new Date(row.created_at).toISOString().split("T")[0];
    if (!acc[dateKey]) acc[dateKey] = [];
    acc[dateKey].push(row);
    return acc;
  }, {});
};

/**
 * Exports the table to Excel.
 */
const exportToExcel = (data, restaurantName, date) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Feedback");

  const fileName = `${restaurantName}-${date}.xlsx`;
  XLSX.writeFile(workbook, fileName);
};

const FeedbackTable = ({ data, restaurantName }) => {
  if (!data || data.length === 0) {
    return <Typography sx={{ marginTop: 3, textAlign: "center" }}>No data available.</Typography>;
  }

  // Group data by date (Newest first)
  const groupedData = groupDataByDate(data);
  const sortedDates = Object.keys(groupedData).sort((a, b) => new Date(b) - new Date(a));

  return (
    <Box sx={{ maxWidth: "100%",  padding: 3 }}>
      {sortedDates.map((date, index) => (
        <Box key={index} sx={{ marginBottom: 4 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 2 }}>
            <Typography variant="h8" sx={{ marginTop: "10px" }}>{date}</Typography>
            <Button variant="outlined" color="primary" onClick={() => exportToExcel(groupedData[date], restaurantName, date)}>
              Export
            </Button>
          </Box>

          <TableContainer component={Paper} sx={{ width: "100%" }}>
            <Table>
              <TableHead>
                <TableRow>
                  {Object.keys(groupedData[date][0])
                    .filter((col) => 
                      col !== "rsid" && 
                      col !== "order_id" &&
                      col !== "created_at_unix" && 
                      col !== "userid" &&
                      col !== "order_details"
                    )
                    .map((col, index) => (
                      <TableCell key={index} sx={{ fontWeight: "bold" }}>{col.toUpperCase()}</TableCell>
                    ))}
                  <TableCell sx={{ fontWeight: "bold" }}>ORDER DETAILS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupedData[date].map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {Object.keys(row)
                      .filter((col) => 
                        col !== "rsid" && 
                        col !== "order_id" &&
                        col !== "created_at_unix" && 
                        col !== "userid" &&
                        col !== "order_details"
                      )
                      .map((col, colIndex) => (
                        <TableCell key={colIndex}>{row[col]}</TableCell>
                      ))}
                        <TableCell>
                            {row.order_details.length > 0 ? (
                                row.order_details.map((item, idx) => (
                                <Typography key={idx} sx={{ display: "flex", alignItems: "center" ,fontSize: "14px"}}>
                                    <span style={{ marginRight: "8px", fontSize: "18px", fontWeight: "bold" }}>•</span>
                                    {item.name} - {item.quantity} x ₹{item.price}
                                </Typography>
                                ))
                            ) : (
                                "N/A"
                            )}
                            </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ))}
    </Box>
  );
};

export default FeedbackTable;
